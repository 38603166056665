export const ENV = process.env.REACT_APP_ENV;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
export const ALGOLIA_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_PRODUCTS_INDEX;
export const ALGOLIA_PRODUCTS_APIKEY =
  process.env.REACT_APP_ALGOLIA_PRODUCTS_APIKEY;
export const ALGOLIA_FEATURED_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_FEATURED_PRODUCTS_INDEX;
export const ALGOLIA_POPULAR_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_POPULAR_PRODUCTS_INDEX;
export const ALGOLIA_LATEST_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_LATEST_PRODUCTS_INDEX;
export const ALGOLIA_PRICE_ASC_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_PRICE_ASC_PRODUCTS_INDEX;
export const ALGOLIA_PRICE_DESC_PRODUCTS_INDEX =
  process.env.REACT_APP_ALGOLIA_PRICE_DESC_PRODUCTS_INDEX;
export const FIREBASE_CONFIG_APIKEY =
  process.env.REACT_APP_FIREBASE_CONFIG_APIKEY;
export const FIREBASE_CONFIG_AUTHDOMAIN =
  process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN;
export const FIREBASE_CONFIG_DBURL =
  process.env.REACT_APP_FIREBASE_CONFIG_DBURL;
export const FIREBASE_CONFIG_PROJECTID =
  process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID;
export const FIREBASE_CONFIG_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET;
export const FIREBASE_CONFIG_MESSAGE_SENDERID =
  process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDERID;
export const FIREBASE_CONFIG_APPID =
  process.env.REACT_APP_FIREBASE_CONFIG_APPID;
export const FIREBASE_CONFIG_MESSAGE_MEASUREMENTID =
  process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_MEASUREMENTID;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const DEFAULT_CURRENCY = process.env.REACT_APP_DEFAULT_CURRENCY;
export const DEFAULT_CURRENCY_SHORT =
  process.env.REACT_APP_DEFAULT_CURRENCY_SHORT;

export const IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST =
  process.env.REACT_APP_IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST === "ENABLED";

export const IS_INTERNATIONAL_SHIPPING =
  process.env.REACT_APP_IS_INTERNATIONAL_SHIPPING === "ENABLED";

export const IS_BUY_ITEMS = process.env.REACT_APP_IS_BUY_ITEMS === "ENABLED";

export const IS_ENABLED_SHIPPING_COST_API =
  process.env.REACT_APP_IS_ENABLED_SHIPPING_COST_API === "ENABLED";

export const BUSINESS_NAME = process.env.REACT_APP_BUSINESS_NAME;

export const IS_DISABLED_PAYMENT =
  process.env.REACT_APP_IS_DISABLED_PAYMENT === "DISABLED";

export const IS_ENABLED_HOT_DEAL_SECTION_HOME_PAGE =
  process.env.REACT_APP_IS_ENABLED_HOT_DEAL_SECTION_HOME_PAGE === "ENABLED";

export const IS_ENABLED_TOP_BRAND_SECTION_HOME_PAGE =
  process.env.REACT_APP_IS_ENABLED_TOP_BRAND_SECTION_HOME_PAGE === "ENABLED";

export const IS_ENABLED_TOP_SELLERS_SECTION_HOME_PAGE =
  process.env.REACT_APP_IS_ENABLED_TOP_SELLERS_SECTION_HOME_PAGE === "ENABLED";

export const G_RECAPTCHA_SITEKEY = process.env.REACT_APP_G_RECAPTCHA_SITEKEY;

export const ANDROID_APP = process.env.REACT_APP_ANDROID_APP;

export const IOS_APP = process.env.REACT_APP_IOS_APP;

export const APP_GALLERY = process.env.REACT_APP_APP_GALLERY;

export const BLOG_SELLER_INFO = process.env.REACT_APP_BLOG_SELLER_INFO;

export const IS_ENABLED_ADD_PRODUCT_FORM_SHIPPING_DETAILS =
  process.env.REACT_APP_IS_ENABLED_ADD_PRODUCT_FORM_SHIPPING_DETAILS ===
  "ENABLED";

export const IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS =
  process.env
    .REACT_APP_IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS ===
  "ENABLED";

export const IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD =
  process.env.REACT_APP_IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD ===
  "ENABLED";

export const IS_ENABLED_APPLE_LOGIN =
  process.env.REACT_APP_IS_ENABLED_APPLE_LOGIN === "ENABLED";

export const IS_ENABLED_INTERNATIONAL_MODE =
  process.env.REACT_APP_IS_ENABLED_INTERNATIONAL_MODE === "ENABLED";

export const IS_ENABLED_BUYER_PROTECTION =
  process.env.REACT_APP_IS_ENABLED_BUYER_PROTECTION === "ENABLED";

export const IS_ENABLED_PLATFORM_FEATURES_BAR =
  process.env.REACT_APP_IS_ENABLED_PLATFORM_FEATURES_BAR === "ENABLED";

export const IS_ENABLED_PROMOTION_MODULE =
  process.env.REACT_APP_IS_ENABLED_PROMOTION_MODULE === "ENABLED";

export const IS_ENABLED_SUPPORT_CENTER_MODULE =
  process.env.REACT_APP_IS_ENABLED_SUPPORT_CENTER_MODULE === "ENABLED";

export const IS_ENABLED_FAQ_MODULE =
  process.env.REACT_APP_IS_ENABLED_FAQ_MODULE === "ENABLED";

export const IS_ENABLED_MOBILE_APP_NOTIFY =
  process.env.REACT_APP_IS_ENABLED_MOBILE_APP_NOTIFY === "ENABLED";

// SOCIAL MEDIA ------------
export const SOCIAL_MEDIA_FB = process.env.REACT_APP_SOCIAL_MEDIA_FB;
export const SOCIAL_MEDIA_TWITTER = process.env.REACT_APP_SOCIAL_MEDIA_TWITTER;
export const SOCIAL_MEDIA_LINKEDIN =
  process.env.REACT_APP_SOCIAL_MEDIA_LINKEDIN;
export const SOCIAL_MEDIA_INSTAGRAM =
  process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM;
export const SOCIAL_MEDIA_PINTEREST =
  process.env.REACT_APP_SOCIAL_MEDIA_PINTEREST;

export const TRADESBARN = "Tradesbarn";
export const ONSHOP = "onShop.lk";

export const INTERNATIONAL = "INTERNATIONAL";
export const DOMESTIC = "DOMESTIC";
export const AUTH_POPUP_CLOSED_BY_USER = "auth/popup-closed-by-user";

export const IS_DOMESTIC_MODE =
  process.env.REACT_APP_E_COMMERCE_MODE === DOMESTIC;

export const IS_INTERNATIONAL_MODE =
  process.env.REACT_APP_E_COMMERCE_MODE === INTERNATIONAL;

// Notification Toaster
export const SUCCESS_TOASTER_TIMEOUT =
  process.env.REACT_APP_SUCCESS_TOASTER_TIMEOUT;
export const ERROR_TOASTER_TIMEOUT =
  process.env.REACT_APP_ERROR_TOASTER_TIMEOUT;

export const IS_ENABLED_CASH_ON_DELIVERY =
  process.env.REACT_APP_IS_ENABLED_CASH_ON_DELIVERY === "ENABLED";

export const IS_ENABLED_CARD_PAYMENT =
  process.env.REACT_APP_IS_ENABLED_CARD_PAYMENT === "ENABLED";

export const IS_ENABLED_PAYPAL_PAYMENT =
  process.env.REACT_APP_IS_ENABLED_PAYPAL_PAYMENT === "ENABLED";

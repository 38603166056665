import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { singleProductActions } from "./ducks";
import { cartActions } from "../Cart/ducks";
import SimilarProduct from "components/Buyer/Carousel/SimilarProductCarousel/SimilarProductCarousel";
import ProductDetails from "../../components/Buyer/SingleProduct/ProductDetails/ProductDetails";
import ProductReview from "../../components/Buyer/SingleProduct/ProductReview/ProductReview";
import ProductQnA from "../../components/Buyer/SingleProduct/ProductQnA/ProductQnA";
import CartNotifyModal from "../../components/Buyer/Modal/CartNotifyModal/CartNotifyModal";
import ProductViewSection from "../../components/Buyer/SingleProduct/ProductViewSection/ProductViewSection";
import { chatActions } from "../ChatPage/ducks";
import SomethingWentWrongPage from "../../components/Common/utilityPages/SomethingWentWrongPage";
import { GetIdToken } from "../../services/auth.service";
import SingleProductLoading from "./SingleProductLoading";
import SingleProductSideBanner from "../../components/Promotions/SingleProductPage/SingleProductSideBanner";
// import "./SingleProduct.less";
import SEO from "../SEO/SEO";
import { isMobile } from "react-device-detect";

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productID: null,
      token: null,
      hasReview: true,
      user: null,
      isOwnProduct: false,
    };
  }

  componentDidMount() {
    this.getCurrentUserToken();
    this.getCurrentUser();
  }

  componentDidUpdate(prevProps, prevState) {
    let productId =
      (this.props.match.params && this.props.match.params.id) || null;
    let prevProductId =
      (prevProps.match.params && prevProps.match.params.id) || null;

    if (productId && productId !== prevProductId) {
      if (this.state.user && this.state.user.role === "Seller") {
        let sellerId =
          this.props.product &&
          this.props.product.data &&
          this.props.product.data.data &&
          this.props.product.data.data.seller &&
          this.props.product.data.data.seller.id;
        let userId = this.state.user.user_id;

        this.setState({
          isOwnProduct: sellerId === userId,
        });
      } else {
        this.setState({
          isOwnProduct: false,
        });
      }
    }
  }

  getCurrentUserToken = async () => {
    let token = await GetIdToken();
    this.setState({ token });
  };

  getCurrentUser = async () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({ user });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { id } = nextProps.match.params;
    if (id && id !== prevState.productID) {
      window.scrollTo(0, 0);
      nextProps.cartActions.clearUserSelections();
      nextProps.cartActions.getProduct({
        id: id,
      });
      return {
        productID: id,
      };
    }
  }

  componentWillUnmount() {
    this.props.cartActions.clearProductInfo();
  }

  visitStoreHandler = () => {
    const { product } = this.props;
    var sellerID =
      product && product.data && product.data.data && product.data.data.seller
        ? product.data.data.seller.id
        : null;
    this.props.history.push(`/seller/${sellerID}`);
  };

  reviewHandler = (hasReview) => {
    this.setState({ hasReview });
  };

  render() {
    const { product, relatedProductsForProduct } = this.props;
    const { token, user } = this.state;
    let sellerId =
      product &&
      product.data &&
      product.data.data &&
      product.data.data.seller &&
      product.data.data.seller.id;
    let userId = user && user.user_id;
    let isOwnProduct = sellerId === userId;
    if (product.loading) {
      return (
        <div className="gx-container mt-4">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                style={{ backgroundColor: "rgb(250, 250, 250)" }}
              >
                <SingleProductLoading />
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else if (product.hasError) {
      return (
        <div>
          <SomethingWentWrongPage />
        </div>
      );
    } else {
      return (
        <div className="gx-container pt-4">
          <SEO
            title={
              product?.data?.data?.name
                ? `${product?.data?.data?.name} | ${process.env.REACT_APP_BUSINESS_NAME}`
                : `${process.env.REACT_APP_BUSINESS_NAME}`
            }
            image={product?.data?.data?.images[0]}
          />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <ProductViewSection product={product} />
            </Col>
          </Row>

          {isMobile ? (
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <div className="gx-card p-3 slr-bcgd flex-fill">
                    <ProductDetails product={product} />
                    {!relatedProductsForProduct.loading &&
                    product &&
                    product.data &&
                    product.data.data &&
                    product.data.data.is_reviews_exists ? (
                      <ProductReview
                        product={relatedProductsForProduct}
                        algolia_api_key={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .reviews
                        }
                      />
                    ) : null}
                    {!relatedProductsForProduct.loading && (
                      <ProductQnA
                        token={token}
                        isOwnProduct={isOwnProduct}
                        algolia_api_key={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .faqs
                        }
                        algolia_api_key_my_faqs={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .my_faqs
                        }
                        isFaqsExists={
                          product &&
                          product.data &&
                          product.data.data &&
                          product.data.data.is_faqs_exists
                        }
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xl={6} lg={8} md={8} sm={24} xs={24}>
                <SingleProductSideBanner />
              </Col>
              <Col xl={18} lg={16} md={16} sm={24} xs={24}>
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <div className="gx-card p-3 slr-bcgd flex-fill">
                    <ProductDetails product={product} />
                    {!relatedProductsForProduct.loading &&
                    product &&
                    product.data &&
                    product.data.data &&
                    product.data.data.is_reviews_exists ? (
                      <ProductReview
                        product={relatedProductsForProduct}
                        algolia_api_key={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .reviews
                        }
                      />
                    ) : null}
                    {!relatedProductsForProduct.loading && (
                      <ProductQnA
                        token={token}
                        isOwnProduct={isOwnProduct}
                        algolia_api_key={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .faqs
                        }
                        algolia_api_key_my_faqs={
                          relatedProductsForProduct &&
                          relatedProductsForProduct.data &&
                          relatedProductsForProduct.data.data &&
                          relatedProductsForProduct.data.data
                            .algolia_api_keys &&
                          relatedProductsForProduct.data.data.algolia_api_keys
                            .my_faqs
                        }
                        isFaqsExists={
                          product &&
                          product.data &&
                          product.data.data &&
                          product.data.data.is_faqs_exists
                        }
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <SimilarProduct
                product={relatedProductsForProduct}
                loading={relatedProductsForProduct.loading}
              />
            </Col>
          </Row>
          <CartNotifyModal />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    product: state.Cart.product,
    shippingCost: state.Cart.shippingCost,
    relatedProductsForProduct: state.Cart.relatedProductsForProduct,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    singleProductActions: bindActionCreators(singleProductActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    chatActions: bindActionCreators(chatActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleProduct)
);

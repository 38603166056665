import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Modal, message, Row, Col, Card } from "antd";
import { withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import { domesticShippingActions } from "../../../../modules/DomesticShipping/ducks";
import {
  getSelecetedVariationProductPrice,
  getSelecetedVariationProductQty,
  getAvailableVariationTypes__firstSelection,
  getAvailableVariationTypes__secondSelection,
  getSelecetedVariationProductOldPrice,
  getValidationVariationSelections,
  checkMyCart,
} from "../../../../util/services";
import ShippingInfoModal from "../../Shipping/ShippingInfoModal/ShippingInfoModal";
import QuantityManager from "../../../Common/QuantityManager/QuantityManager";
// import "./ProductInfo.less";
import StarRatingComponent from "react-star-rating-component";
import ProductRightBar from "../ProductRightBar/ProductRightBar";
import {
  DEFAULT_CURRENCY,
  IS_BUY_ITEMS,
  IS_DOMESTIC_MODE,
  IS_ENABLED_SHIPPING_COST_API,
} from "../../../../constants";
import { chatActions } from "../../../../modules/ChatPage/ducks";
import { contactSellerHandler } from "../../../../services/message.service";
// import LocationPicker from "./LocationPicker";

import { GetDeliveryAreasByDistrict } from "../../../../firebase/functions";
// import store from "../../../../store";
import LocationPickerModal from "./LocationPickerModal";

const ButtonGroup = Button.Group;
// const { confirm } = Modal;
class DomesticProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the CRM column
      loading: false,
      myCartObj: null,
      selectedQuantity: 1,
      variation_1: null,
      variation_2: null,
      variation_3: null,
      variation_values_1: [],
      variation_values_2: [],
      variation_values_3: [],
      noOfVaiations: null,
      variationValues: null,
      userSelectedVarition: false,
      userSelectedVariationObject: {},
      availablities: {},
      availablitiesState: false,
      userDomesticLocation: undefined,
      disble: 0,
      LocationPickerModalVisible: false,
      product: {
        data: {
          data: {
            variation: null,
          },
        },
      },
      selectedItem: null,
      selectedProductPriceObj: null,
      selectedProductQty: null,
      operation: null,
    };
  }

  getShippingCostHandler = () => {
    // var myCartString = localStorage.getItem("myCart");
    // if (myCartString) {
    //   var myCartObj = JSON.parse(myCartString);
    //   // this.setState({ myCartObj });
    //   if (myCartObj.length !== 0) {
    //     this.props.cartActions.initializeReduxCart({ myCartObj });
    //     this.props.cartActions.checkOrderQuantity({
    //       defaultShippingAddress: null,
    //       cartObj: myCartObj,
    //     });
    //     myCartObj.map((product, i) => {
    //       product_ids.push(product.id);
    //       return null;
    //     });
    //     this.props.cartActions.getRelatedProducts({ product_ids: product_ids });
    //   }
    // }

    const { data: product } = this.props.product.data;
    var selectedLocationString = localStorage.getItem("selectedLocation");
    if (!!selectedLocationString) {
      var selectedLocation = JSON.parse(selectedLocationString);
      this.props.cartActions.initializeLocationPickerValue({
        selectedLocation,
      });

      this.props.cartActions.getShippingCost({
        id: product.id,
        // isCart: true,
        deliveryAreaId: selectedLocation.id,
      });
    }
  };
  componentDidMount() {
    const { data } = this.props.product.data;

    var variations = data && data.variations;
    this.getShippingCostHandler();
    this.getUserDomesticLocation();
    this.getDomesticShippingLocations();

    // initializa variations
    var variation_1 = null;
    var variation_2 = null;
    var variation_3 = null;

    var variationTypes = data && data.variation_types;
    if (data && data.variation_types) {
      var noOfVaiations = data.variation_types.length;
      if (noOfVaiations === 1) {
        variation_1 = data.variation_types[0];
      } else if (noOfVaiations === 2) {
        variation_1 = data.variation_types[0];
        variation_2 = data.variation_types[1];
      } else if (noOfVaiations === 3) {
        variation_1 = data.variation_types[0];
        variation_2 = data.variation_types[1];
        variation_3 = data.variation_types[2];
      }
    }
    var variation_values_1 = [];
    var variation_values_2 = [];
    var variation_values_3 = [];
    var variationValues = data && data.variation_values;
    if (data && data.variation_values) {
      if (noOfVaiations === 1) {
        variation_values_1 = data.variation_values[variation_1];
      } else if (noOfVaiations === 2) {
        variation_values_1 = data.variation_values[variation_1];
        variation_values_2 = data.variation_values[variation_2];
      } else if (noOfVaiations === 3) {
        variation_values_1 = data.variation_values[variation_1];
        variation_values_2 = data.variation_values[variation_2];
        variation_values_3 = data.variation_values[variation_3];
      }
    }

    this.setState({
      variations,
      variation_1,
      variation_2,
      variation_3,
      noOfVaiations,
      variation_values_1,
      variation_values_2,
      variation_values_3,
      variationTypes,
      variationValues,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product !== prevState.product) {
      this.setState({
        product: this.props.product,
      });
    }
    if (
      this.props.shippingCost &&
      JSON.stringify(this.props.shippingCost) !==
        JSON.stringify(prevProps.shippingCost)
    ) {
      this.setState({
        shippingCost: this.props.shippingCost,
      });
    }
  }

  getUserDomesticLocation = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      userDomesticLocation: user?.domesticLocation,
    });
  };

  getDomesticShippingLocations = () => {
    this.props.domesticShippingActions.getDomesticLocations();
  };

  getLocationDetails = (
    item,
    productPriceObj,
    productQty,
    handler,
    operation
  ) => {
    const { shippingCost } = this.props;
    // debugger;
    var selectedLocationString = localStorage.getItem("selectedLocation");

    // var selectedLocation = JSON.parse(selectedLocationString);

    if (
      !(!shippingCost.loading && !shippingCost.hasError) ||
      !selectedLocationString
    ) {
      // debugger;
      this.setState({
        operation: operation,
        selectedItem: item,
        selectedProductPriceObj: productPriceObj,
        selectedProductQty: productQty,
        LocationPickerModalVisible: true,
      });
    } else {
      this.setState({
        operation: operation,
        selectedItem: item,
        selectedProductPriceObj: productPriceObj,
        selectedProductQty: productQty,
      });
      operation === "BUY_NOW"
        ? this.buyNowHandler(item, productPriceObj, productQty)
        : this.addCartHandler(item, productPriceObj, productQty);
      // this.addCartHandler(item, productPriceObj, productQty);
    }

    // this.setState({
    //   operation: operation,
    //   selectedItem: item,
    //   selectedProductPriceObj: productPriceObj,
    //   selectedProductQty: productQty,
    //   LocationPickerModalVisible: true,
    // });

    // confirm({
    //   title: "Some information is missing regarding your order",
    //   content: (
    //     <Provider store={store}>
    //       <div className="mb-2">Location :</div>
    //       <div>
    //         <LocationPicker
    //           locationOnChangeHandler={this.locationOnChangeHandler}
    //         />
    //       </div>
    //     </Provider>
    //   ),
    //   okButtonProps: {
    //     // disabled: !true,
    //     loading: this.state?.shippingCost?.pending || false,
    //   },
    //   cancelText: "No",
    //   onOk() {
    //     
    //     handler(item, productPriceObj, productQty);
    //   },
    //   onCancel() {
    //     
    //   },
    // });

    // this.setState({
    //   LocationPickerModalVisible: true,
    // });
  };

  toggleLocationPickerModalHandler = () => {
    // debugger;
    // const { selectedItem, selectedProductPriceObj, selectedProductQty } =
    //   this.state;
    // this.addCartHandler(
    //   selectedItem,
    //   selectedProductPriceObj,
    //   selectedProductQty
    // );
    this.setState({
      LocationPickerModalVisible: !this.state.LocationPickerModalVisible,
    });
  };

  setDomesticLocation = (item, productPriceObj, productQty) => {
    this.setState({
      selectedItem: item,
      selectedProductPriceObj: productPriceObj,
      selectedProductQty: productQty,
      LocationPickerModalVisible: true,
    });
    // const { shippingCost } = this.props;
    // var selectedLocationString = localStorage.getItem("selectedLocation");

    // // var selectedLocation = JSON.parse(selectedLocationString);
    // if (
    //   (!shippingCost.loading && !shippingCost.hasError) ||
    //   selectedLocationString
    // ) {
    //   confirm({
    //     title: "Some information is missing regarding your order",
    //     content: (
    //       <Provider store={store}>
    //         <div className="mb-2">Location :</div>
    //         <div>
    //           <LocationPicker
    //             locationOnChangeHandler={this.locationOnChangeHandler}
    //           />
    //         </div>
    //       </Provider>
    //     ),
    //     confirmLoading: true,
    //     // okButtonProps: {
    //     //   disabled: shippingCost.pending,
    //     // },
    //     okButtonProps: {
    //       // disabled: !true,
    //       loading: this.props.shippingCost.pending,
    //     },
    //     cancelButtonProps: {
    //       loading: this.props.shippingCost.pending,
    //     },
    //     cancelText: "No",
    //     onOk() {
    //       
    //       // handler(item, productPriceObj, productQty);
    //     },
    //     onCancel() {
    //       
    //     },
    //   });
    // } else {
    //   // debugger;
    //   this.setState({
    //     selectedItem: item,
    //     selectedProductPriceObj: productPriceObj,
    //     selectedProductQty: productQty,
    //     LocationPickerModalVisible: true,
    //   });
    // }
  };

  addCartHandler = (item, productPriceObj, productQty) => {
    // debugger;
    const { variation_1, variation_2, variation_3, noOfVaiations } = this.state;
    const { userSelections, myCart, shippingCost } = this.props;
    const { isAllSelected, productPrice } = productPriceObj;
    let shippingDetails =
      !shippingCost.loading && shippingCost.data && shippingCost.data.data;

    // Outof stock handle
    if (
      productQty == null ||
      productQty === 0 ||
      !shippingDetails.shipping_available
    ) {
      Modal.info({
        title: "Sorry",
        content: (
          <div>
            <p>
              {!shippingDetails.shipping_available
                ? shippingDetails.message
                : "Selected item is not availble now.."}
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      if (productPrice) {
        var validateVariationsSelection = getValidationVariationSelections(
          variation_1,
          variation_2,
          variation_3,
          userSelections
        );

        var errMessage_1 =
          validateVariationsSelection &&
          validateVariationsSelection.errMessage_1;
        var errMessage_2 =
          validateVariationsSelection &&
          validateVariationsSelection.errMessage_2;
        var errMessage_3 =
          validateVariationsSelection &&
          validateVariationsSelection.errMessage_3;

        if (!noOfVaiations) {
          // handle none variation product
          //check selected item in already in the cart
          const updatedMyCart = checkMyCart(myCart.items, item);
          if (updatedMyCart.isAlreadyInMyCart) {
            this.props.cartActions.updateExistCartItem({
              myCart: updatedMyCart.myCart,
              displaySucess: true,
            });
          } else {
            this.props.cartActions.addCartItem({
              item: item,
              displaySucess: true,
            });
          }
        } else if (!isAllSelected) {
          // alert(errMessage_1 + errMessage_2 + errMessage_3);
          Modal.info({
            title: "Some information is missing regarding your order",
            content: (
              <div>
                {errMessage_1 && (
                  <p className="mb-1 cancle-clr">{errMessage_1}</p>
                )}
                {errMessage_2 && (
                  <p className="mb-1 cancle-clr">{errMessage_2}</p>
                )}
                {errMessage_3 && (
                  <p className="mb-1 cancle-clr">{errMessage_3}</p>
                )}
              </div>
            ),
            onOk() {},
          });
        } else if (!userSelections.quantity) {
          Modal.info({
            title: "This is a notification message",
            content: (
              <div>
                <p>Please select the Quantity</p>
              </div>
            ),
            onOk() {},
          });
        } else {
          //check selected item in already in the cart
          const updatedMyCart = checkMyCart(myCart.items, item);
          if (updatedMyCart.isAlreadyInMyCart) {
            this.props.cartActions.updateExistCartItem({
              myCart: updatedMyCart.myCart,
              displaySucess: true,
            });
          } else {
            this.props.cartActions.addCartItem({
              item: item,
              displaySucess: true,
            });
          }
        }
      } else {
        Modal.info({
          title: "Sorry",
          content: (
            <div>
              <p>{`Please check the ${variation_1}, ${variation_2} ${
                variation_3 ? `and ${variation_3}` : null
              }`}</p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  };

  addWishlistHandler = (item) => {
    // history.push("/login?redirect_to=/account");

    const user = localStorage.getItem("user");
    const currentRoute = this.props.match.url;
    if (!user) {
      // message.info("Please login...", 3);
      const modal = Modal.info({
        title: "Login required.. ",
        content: "Please log into the system",
      });
      setTimeout(() => modal.destroy(), 3000);

      //wait 3 seconds
      setTimeout(
        function () {
          this.props.history.push(`/login?redirect_to=${currentRoute}`);
        }.bind(this),
        3000
      );
    } else {
      var wishlistDto = {
        product_id: item.id,
      };
      this.props.cartActions.addWishlistItem({
        wishlistDto: wishlistDto,
        isSingleProduct: true,
      });

      this.setState({
        product: {
          ...this.state.product,
          data: {
            ...this.state.product.data,
            data: {
              ...this.state.product.data.data,
              is_wishlisted: !this.state.product.data.data.is_wishlisted,
            },
          },
        },
      });
    }
  };

  buyNowHandler = (item, productPriceObj, productQty) => {
    // debugger;
    const { variation_1, variation_2, variation_3, noOfVaiations } = this.state;
    const { userSelections, shippingCost } = this.props;
    const { isAllSelected, productPrice } = productPriceObj;
    let shippingDetails =
      !shippingCost.loading && shippingCost.data && shippingCost.data.data;

    // debugger;
    const user = localStorage.getItem("user");
    const currentRoute = this.props.match.url;

    if (
      productQty == null ||
      productQty === 0 ||
      !shippingDetails.shipping_available
    ) {
      Modal.info({
        title: "Sorry",
        content: (
          <div>
            <p className="mb-1">
              {!shippingDetails.shipping_available
                ? shippingDetails.message
                : "Selected item is not availble now.."}
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      if (!user) {
        // message.info("Please login...", 3);
        const modal = Modal.info({
          title: "Login required.. ",
          content: "Please log into the system",
        });
        setTimeout(() => modal.destroy(), 3000);

        //wait 3 seconds
        setTimeout(
          function () {
            this.props.history.push(`/login?redirect_to=${currentRoute}`);
          }.bind(this),
          3000
        );
      } else {
        if (productPrice) {
          var validateVariationsSelection = getValidationVariationSelections(
            variation_1,
            variation_2,
            variation_3,
            userSelections
          );

          var errMessage_1 =
            validateVariationsSelection &&
            validateVariationsSelection.errMessage_1;
          var errMessage_2 =
            validateVariationsSelection &&
            validateVariationsSelection.errMessage_2;
          var errMessage_3 =
            validateVariationsSelection &&
            validateVariationsSelection.errMessage_3;

          if (!noOfVaiations) {
            this.props.cartActions.checkoutItems({ item: item });
            this.props.history.push("/checkout");
          } else if (!isAllSelected) {
            // alert(errMessage_1 + errMessage_2 + errMessage_3);
            Modal.info({
              title: "This is a notification message",
              content: (
                <div>
                  {errMessage_1 && (
                    <p className="mb-1 cancle-clr">{errMessage_1}</p>
                  )}
                  {errMessage_2 && (
                    <p className="mb-1 cancle-clr">{errMessage_2}</p>
                  )}
                  {errMessage_3 && (
                    <p className="mb-1 cancle-clr">{errMessage_3}</p>
                  )}
                </div>
              ),
              onOk() {},
            });
          } else if (!userSelections.quantity) {
            Modal.info({
              title: "This is a notification message",
              content: (
                <div>
                  <p>Please select the Quantity</p>
                </div>
              ),
              onOk() {},
            });
          } else {
            this.props.cartActions.checkoutItems({ item: item });
            this.props.history.push("/checkout");
            // alert("Done");
          }
        } else {
          Modal.info({
            title: "Sorry",
            content: (
              <div>
                <p>{`Please check the ${variation_1}, ${variation_2} ${
                  variation_3 ? `and ${variation_3}` : null
                }`}</p>
              </div>
            ),
            onOk() {},
          });
        }
      }
    }
  };

  quantityIncreaseHandler = (isEnabled, available, index) => {
    const { userSelections } = this.props;
    if (isEnabled) {
      if (userSelections.quantity >= available) {
        message.info(`Only ${available} available`);
      } else {
        // this.setState({ selectedQuantity: selectedQuantity + 1 });
        this.props.cartActions.productUserSelectionsHandler({
          key: "quantity",
          value: userSelections.quantity + 1,
        });
      }
    } else if (available === 0) {
      message.warning("Sorry this selection is out of stock");
    } else {
      message.warning("Please check the variation");
    }
  };

  quantityDecreaseHandler = (isEnabled, available, index) => {
    const { userSelections } = this.props;

    if (isEnabled) {
      if (userSelections.quantity <= 0) {
        message.info(`Only ${available} available`);
      } else {
        // this.setState({ selectedQuantity: userSelections.quantity - 1 });
        // alert("Decrease");
        this.props.cartActions.productUserSelectionsHandler({
          key: "quantity",
          value: userSelections.quantity - 1,
        });
      }
    } else if (available === 0) {
      message.warning("Sorry this selection is out of stock");
    } else {
      message.warning("Please check the variation");
    }
  };

  quantityHandler = (quantity, available, index) => {
    if (parseInt(quantity) > available) {
      this.setState({ selectedQuantity: available });
      this.props.cartActions.productUserSelectionsHandler({
        key: "quantity",
        value: available,
      });
    } else {
      this.setState({ selectedQuantity: quantity });
      this.props.cartActions.productUserSelectionsHandler({
        key: "quantity",
        value: quantity,
      });
    }
  };

  variationSelectHandler = (
    variationLabel,
    value,
    variationLabelX,
    variationLabelY
  ) => {
    const {
      variations,
      variation_1,
      variation_2,
      variation_3,
      variation_values_1,
      variation_values_2,
      variation_values_3,
      noOfVaiations,
      variationTypes,
      variationValues,
    } = this.state;
    const { userSelections } = this.props;
    const { data } = this.state.product.data;

    var userSelectedVariations =
      userSelections && userSelections.variations
        ? userSelections.variations
        : {};

    var userSelectedVariationsArray = Object.keys(userSelectedVariations);
    userSelectedVariationsArray.push(variationLabel);
    //get unique userSelectedVariationsArray
    var uniqUserSelectedVariationsArray = [
      ...new Set(userSelectedVariationsArray),
    ];
    //De-select Handler
    if (
      userSelections.variations &&
      userSelections.variations[variationLabel] === value
    ) {
      uniqUserSelectedVariationsArray = uniqUserSelectedVariationsArray.filter(
        (item) => item !== variationLabel
      );
    }

    var prevUserSelectedVariations = uniqUserSelectedVariationsArray.filter(
      (item) => item !== variationLabel
    );

    //De-select Handler
    if (
      userSelections.variations &&
      userSelections.variations[variationLabel] === value
    ) {
      if (noOfVaiations === 1) {
        this.props.cartActions.deselectVariations({
          key: variationLabel,
        });
      } else if (noOfVaiations === 2) {
        var updated_variation_values_1 = variation_values_1;
        var updated_variation_values_2 = variation_values_2;
        var updated_variation_values_3 = variation_values_3;
        // debugger;
        this.props.cartActions.deselectVariations({
          key: variationLabel,
        });

        if (variation_1 === variationLabelX) {
          updated_variation_values_1 =
            data && data.variation_values[variationLabelX];
        }
        if (variation_2 === variationLabelX) {
          updated_variation_values_2 =
            data && data.variation_values[variationLabelX];
        }
        if (variation_3 === variationLabelX) {
          updated_variation_values_3 =
            data && data.variation_values[variationLabelX];
        }
        this.setState({
          variation_values_1: updated_variation_values_1,
          variation_values_2: updated_variation_values_2,
          variation_values_3: updated_variation_values_3,
        });
      } else if (noOfVaiations === 3) {
        let updated_variation_values_1 = variation_values_1;
        let updated_variation_values_2 = variation_values_2;
        let updated_variation_values_3 = variation_values_3;
        // debugger;
        this.props.cartActions.deselectVariations({
          key: variationLabel,
        });
        // debugger;
        if (prevUserSelectedVariations.length === 0) {
          if (variation_1 === variationLabelX) {
            updated_variation_values_1 =
              data && data.variation_values[variationLabelX];
          } else if (variation_2 === variationLabelX) {
            updated_variation_values_2 =
              data && data.variation_values[variationLabelX];
          } else if (variation_3 === variationLabelX) {
            updated_variation_values_3 =
              data && data.variation_values[variationLabelX];
          }

          if (variation_1 === variationLabelY) {
            updated_variation_values_1 =
              data && data.variation_values[variationLabelY];
          } else if (variation_2 === variationLabelY) {
            updated_variation_values_2 =
              data && data.variation_values[variationLabelY];
          } else if (variation_3 === variationLabelY) {
            updated_variation_values_3 =
              data && data.variation_values[variationLabelY];
          }
          this.setState({
            variation_values_1: updated_variation_values_1,
            variation_values_2: updated_variation_values_2,
            variation_values_3: updated_variation_values_3,
          });
        } else if (prevUserSelectedVariations.length === 1) {
          if (variationLabelX === prevUserSelectedVariations[0]) {
            var tempX = variationLabelX;
            variationLabelX = variationLabel;
            variationLabel = tempX;
          } else if (variationLabelY === prevUserSelectedVariations[0]) {
            var tempY = variationLabelY;
            variationLabelY = variationLabel;
            variationLabel = tempY;
          }

          //if select one variable -> get available othor two variable types
          var availableVariationTypes =
            getAvailableVariationTypes__firstSelection(
              variationLabelX,
              variationLabelY,
              variation_values_1,
              variation_values_2,
              variation_values_3,
              variations,
              variationLabel,
              userSelections.variations &&
                userSelections.variations[variationLabel],
              variation_1,
              variation_2,
              variation_3,
              variationValues
            );

          this.setState({
            variation_values_1:
              availableVariationTypes.updated_variation_values_1,
            variation_values_2:
              availableVariationTypes.updated_variation_values_2,
            variation_values_3:
              availableVariationTypes.updated_variation_values_3,
          });
        }
      }
    }
    //variation select handle
    else {
      if (noOfVaiations === 1) {
        this.props.cartActions.selectVariations({
          key: variationLabel,
          value: value,
        });
      } else if (noOfVaiations === 2) {
        this.props.cartActions.selectVariations({
          key: variationLabel,
          value: value,
        });
        //if select one variable -> get available othor two variable types
        availableVariationTypes = getAvailableVariationTypes__firstSelection(
          variationLabelX,
          variationLabelY,
          variation_values_1,
          variation_values_2,
          variation_values_3,
          variations,
          variationLabel,
          value,
          variation_1,
          variation_2,
          variation_3
        );

        this.setState({
          variation_values_1:
            availableVariationTypes.updated_variation_values_1,
          variation_values_2:
            availableVariationTypes.updated_variation_values_2,
          variation_values_3:
            availableVariationTypes.updated_variation_values_3,
        });
      } else if (noOfVaiations === 3) {
        this.props.cartActions.selectVariations({
          key: variationLabel,
          value: value,
        });

        if (prevUserSelectedVariations.length === 0) {
          //if select one variable -> get available othor two variable types
          availableVariationTypes = getAvailableVariationTypes__firstSelection(
            variationLabelX,
            variationLabelY,
            variation_values_1,
            variation_values_2,
            variation_values_3,
            variations,
            variationLabel,
            value,
            variation_1,
            variation_2,
            variation_3,
            variationValues
          );

          this.setState({
            variation_values_1:
              availableVariationTypes.updated_variation_values_1,
            variation_values_2:
              availableVariationTypes.updated_variation_values_2,
            variation_values_3:
              availableVariationTypes.updated_variation_values_3,
          });
        } else if (prevUserSelectedVariations.length === 1) {
          //if selected two variable -> get available othor one variable types
          availableVariationTypes = getAvailableVariationTypes__secondSelection(
            variationLabelX,
            variationLabelY,
            variation_values_1,
            variation_values_2,
            variation_values_3,
            variations,
            variationLabel,
            value,
            variation_1,
            variation_2,
            variation_3,
            prevUserSelectedVariations,
            userSelections,
            variationTypes
          );

          this.setState({
            variation_values_1:
              availableVariationTypes.updated_variation_values_1,
            variation_values_2:
              availableVariationTypes.updated_variation_values_2,
            variation_values_3:
              availableVariationTypes.updated_variation_values_3,
          });
        }
      }
    }
  };

  generateFilters(variations, variation_values) {
    const { userSelectedVariationObject } = this.state;
    var filterVariations = variations;

    Object.keys(userSelectedVariationObject).forEach((key) => {
      filterVariations = filterVariations.filter((o) =>
        Object.entries(o).some(
          ([k, value]) =>
            k === key && value === userSelectedVariationObject[key]
        )
      );
    });
    const filtered = {};

    Object.keys(variation_values).forEach((variation_key) => {
      filtered[variation_key] = filterVariations.map(
        (element) => element[variation_key]
      );
    });
    this.setState({ availablitiesState: true, availablities: filtered });
  }

  variationDisable = (disble) => {
    this.setState({ disble });
  };

  variationSelectHandler2(
    variation,
    variationType,
    variations,
    variation_values,
    index
  ) {
    const { userSelectedVariationObject } = this.state;
    this.setState({ disble: index });

    if (userSelectedVariationObject[variationType] === variation) {
      const copyValues = { ...userSelectedVariationObject };
      delete copyValues[variationType];
      this.setState({ userSelectedVariationObject: copyValues }, () => {
        this.generateFilters(variations, variation_values);
      });

      this.props.cartActions.deselectVariations({
        key: variationType,
      });
    } else {
      this.setState(
        {
          userSelectedVariations: true,
          ...this.state,
          userSelectedVariationObject: {
            ...userSelectedVariationObject,
            [variationType]: variation,
          },
        },
        () => {
          this.generateFilters(variations, variation_values);
        }
      );

      this.props.cartActions.selectVariations({
        key: variationType,
        value: variation,
      });
    }

    var object = variations.filter((o) =>
      Object.entries(o).some(
        ([k, value]) => k === variationType && value === variation
      )
    );

    const filtered = {};
    Object.keys(variation_values).forEach((key) => {
      filtered[key] = object.map((element) => element[key]);
    });
  }

  renderVarions() {
    const { data } = this.props.product.data;
    const {
      userSelectedVariations,
      userSelectedVariationObject,
      availablitiesState,
      availablities,
      disble,
    } = this.state;
    var { variations, variation_types, variation_values } = data;
    if (data && variation_types) {
      return [
        userSelectedVariations ? (
          <React.Fragment>
            {Object.keys(variation_values)
              .sort()
              .map((variationType, index) => {
                return (
                  <React.Fragment>
                    <div className="tr-size-picker-label">
                      <div className="mb-1">
                        {variationType} :{" "}
                        <span style={{ color: "#129c30" }}>
                          {userSelectedVariationObject[variationType]}
                        </span>
                      </div>
                    </div>

                    <ButtonGroup>
                      {variation_values[variationType].map((variation, i) => {
                        return (
                          <React.Fragment key={`${variation}/${i}`}>
                            {userSelectedVariationObject[variationType] ===
                            variation ? (
                              <Button
                                className="new-btn-grp-styl"
                                style={{
                                  color: "#28aaeb",
                                  borderColor: "#28aaeb",
                                }}
                                onClick={() =>
                                  this.variationSelectHandler2(
                                    variation,
                                    variationType,
                                    variations,
                                    variation_values,
                                    index
                                  )
                                }
                              >
                                {variation}
                              </Button>
                            ) : availablitiesState &&
                              availablities[variationType].includes(
                                variation
                              ) ? (
                              <Button
                                className="new-btn-grp-styl foc-hack"
                                onClick={() =>
                                  this.variationSelectHandler2(
                                    variation,
                                    variationType,
                                    variations,
                                    variation_values,
                                    index
                                  )
                                }
                              >
                                {variation}
                              </Button>
                            ) : index !== disble ? (
                              <Button
                                className="new-btn-grp-styl"
                                // disabled
                                onClick={() =>
                                  this.variationSelectHandler2(
                                    variation,
                                    variationType,
                                    variations,
                                    variation_values,
                                    index
                                  )
                                }
                              >
                                {variation}
                              </Button>
                            ) : (
                              <Button
                                className="new-btn-grp-styl"
                                onClick={() =>
                                  this.variationSelectHandler2(
                                    variation,
                                    variationType,
                                    variations,
                                    variation_values,
                                    index
                                  )
                                }
                              >
                                {variation}
                              </Button>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ButtonGroup>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {Object.keys(variation_values)
              .sort()
              .map((variationType) => {
                return (
                  <div>
                    <div>
                      <React.Fragment>
                        <div className="tr-size-picker-label">
                          <div className="mb-1">
                            {variationType} :{" "}
                            <span style={{ color: "#129c30" }}></span>
                          </div>
                        </div>{" "}
                      </React.Fragment>
                    </div>
                    <ButtonGroup>
                      {variation_values[variationType].map((variation, i) => {
                        return (
                          <Button
                            key={`${variation}/${i}`}
                            className="new-btn-grp-styl foc-hack"
                            onClick={() =>
                              this.variationSelectHandler2(
                                variation,
                                variationType,
                                variations,
                                variation_values
                              )
                            }
                          >
                            {variation}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                );
              })}
          </React.Fragment>
        ),
      ];
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  locationOnChangeHandler = (selectedLocation) => {
    // debugger;
    const { data: product } = this.props.product.data;
    // localStorage.setItem("domesticLocation", JSON.stringify(selectedLocation));

    const deliveryAreaId = selectedLocation.id;
    const district = selectedLocation.district;
    const city = selectedLocation.city;

    if (deliveryAreaId) {
      // this.props.domesticShippingActions.getEstimatedShippingCost({
      //   product_id: product.id,
      //   deliveryAreaId: deliveryAreaId,
      // });
      // IS_ENABLED_SHIPPING_COST_API &&
      this.props.cartActions.getShippingCost({
        id: product.id,
        // isCart: true,
        deliveryAreaId: deliveryAreaId,
      });
      this.setState({
        userDomesticLocation: `${district} - ${city}`,
      });
    }
  };

  locationFilterHandler = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  getDeliveryAreasForDistrict = (selectedOptions) => {
    const targetOption = selectedOptions[0];
    targetOption.loading = true;
    GetDeliveryAreasByDistrict(targetOption.label).then((data) => {
      targetOption.loading = false;
      let deliveryAreas = [];

      targetOption.children = deliveryAreas;

      data.forEach((element) => {
        deliveryAreas.push({
          label: element.city,
          value: element,
        });
      });
    });
    // load options lazily
  };

  // renderDomesticShippingInfo = (id, data, selectedShippingMethod) => {
  //   return
  //   );
  // };

  render() {
    const {
      userSelections,
      match,
      shippingCost,
      selectedDomesticShippingLocation,
    } = this.props;
    const currentRoute = match.url;
    const { data } = this.state.product.data;
    const { id } = match.params;
    const { variations, variation_1, variation_2, variation_3, noOfVaiations } =
      this.state;
    const { ratings_count, ratings_value, sold } = data;

    var productPrice = getSelecetedVariationProductPrice(
      variations,
      userSelections,
      noOfVaiations,
      variation_1,
      variation_2,
      variation_3,
      data
    );

    var oldPrice = getSelecetedVariationProductOldPrice(
      variations,
      userSelections,
      noOfVaiations,
      variation_1,
      variation_2,
      variation_3,
      data
    );

    var productQty = getSelecetedVariationProductQty(
      variations,
      userSelections,
      noOfVaiations,
      variation_1,
      variation_2,
      variation_3,
      data
    );
    var selectedShippingMethod =
      this.state.product.data.data.selectedShippingMethod;
    let sellerID = data?.seller?.id;

    return (
      <div className="d-flex flex-column" style={{ height: "100%" }}>
        <Card className="gx-card mb-0 flex-fill">
          <Row>
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              {/* Product Info*/}
              <div style={{ width: "100%", padding: " 0 10px" }}>
                <div
                  className="sp-categories mb-3"
                  style={{ fontSize: "11px" }}
                >
                  <i className="fas fa-tag"></i>&nbsp;
                  {data && data.category && data.category.name}
                </div>
                <h6 className="mb-2">{data && data.name}</h6>

                <div className="ant-row-flex">
                  {ratings_count > 0 ? (
                    <React.Fragment>
                      <StarRatingComponent
                        name=""
                        value={ratings_value}
                        // value={4}
                        starCount={5}
                        editing={false}
                        emptyStarColor={"#4b505e"}
                      />

                      <span className="gx-d-inline-block ml-1">
                        {ratings_value}
                      </span>
                      <span className="mx-2">|</span>
                      <span className="gx-d-inline-block">{`${ratings_count} Reviews`}</span>
                    </React.Fragment>
                  ) : null}
                  {sold ? (
                    <React.Fragment>
                      {ratings_count !== 0 ? (
                        <span className="mx-2">|</span>
                      ) : (
                        ""
                      )}
                      <span className="gx-d-inline-block">{`${sold} sold`}</span>
                    </React.Fragment>
                  ) : null}
                </div>
                {productPrice.productPrice ? (
                  <span className="h3 d-block my-3">
                    <span style={{ color: "#ff6900" }}>
                      {productPrice.productPrice
                        ? `${DEFAULT_CURRENCY} ${productPrice.productPrice}`
                        : null}
                    </span>
                    &nbsp;
                    {data.discount_percentage && oldPrice ? (
                      <del className="text-muted">{`${DEFAULT_CURRENCY} ${oldPrice}`}</del>
                    ) : null}
                    &nbsp; &nbsp;
                    <span style={{ color: "#129c30", fontSize: "20px" }}>
                      {data && data.discount_percentage !== 0
                        ? `${data.discount_percentage} % off`
                        : null}
                    </span>
                  </span>
                ) : (
                  <span className="h3 d-block my-3">
                    <span style={{ color: "#ff6900" }}>{"Not Available"}</span>
                  </span>
                )}

                {data && data.brand ? (
                  <div className="mt-2">
                    Brand :{" "}
                    <span style={{ color: "#129c30" }}>{data.brand}</span>
                  </div>
                ) : null}

                <div className="mt-1">
                  Condition :{" "}
                  <span style={{ color: "#129c30" }}>
                    {data && data.condition}
                  </span>
                </div>
                {/*
                {data && data.condition_status ? (
                  <div className="mt-1">
                    Condition :{" "}
                    <span style={{ color: "#129c30" }}>
                      {toTitleCase(data && data.condition_status)}
                    </span>
                  </div>
                ) : null} */}

                <div className="mt-1">{this.renderVarions()}</div>

                <div className="mb-1">
                  <div className="">
                    Availibility : &nbsp;
                    <span style={{ display: "inline-block", color: "#129c30" }}>
                      {productQty && productQty !== 0 ? (
                        <div>{` ${productQty} In stock`}</div>
                      ) : (
                        <div style={{ color: "red" }}>Out of stock</div>
                      )}
                    </span>
                  </div>
                </div>

                <div className="mb-1">Quantity :</div>
                {/* Web view */}
                <div
                  className="align-items-end display-web-s"
                  style={
                    IS_DOMESTIC_MODE
                      ? { marginBottom: "40px" }
                      : { marginBottom: "60px" }
                  }
                >
                  <div className="">
                    <QuantityManager
                      quantityDecreaseHandler={this.quantityDecreaseHandler}
                      quantityIncreaseHandler={this.quantityIncreaseHandler}
                      selectedQuantity={userSelections.quantity}
                      // available={data && data.available}
                      available={productQty}
                      quantityHandler={this.quantityHandler}
                      isEnabled={
                        productPrice.isAllSelected ||
                        !!productPrice.productPrice
                      }
                    />
                  </div>
                </div>

                {/* {this.state.userDomesticLocation ? (
                  <React.Fragment>
                    <ShippingInfoModal
                      product_id={id}
                      data={data}
                      selectedShippingMethod={selectedShippingMethod}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Link
                      className="crd-fnt-jr"
                      onClick={() => this.setDomesticLocation()}
                    >
                      {`Set Delivery Location`}
                      <i
                        className="fad fa-angle-right ml-1"
                        style={{ fontSize: "20px", verticalAlign: "middle" }}
                      ></i>
                    </Link>
                  </React.Fragment>
                )} */}

                <React.Fragment>
                  <div
                    className="crd-fnt-jr"
                    onClick={() =>
                      this.setDomesticLocation(data, productPrice, productQty)
                    }
                  >
                    {selectedDomesticShippingLocation?.data
                      ? `Delivered to ${selectedDomesticShippingLocation?.data?.district} -
                      ${selectedDomesticShippingLocation?.data?.city}`
                      : "Set Delivery Location"}
                    <i
                      className="fad fa-angle-right ml-1"
                      style={{ fontSize: "20px", verticalAlign: "middle" }}
                    ></i>
                  </div>
                </React.Fragment>

                <React.Fragment>
                  <ShippingInfoModal
                    product_id={id}
                    data={data}
                    selectedShippingMethod={selectedShippingMethod}
                  />
                </React.Fragment>

                {/* Mobile view */}
                <div className="row align-items-end display-mob-s">
                  <div className="col-12">
                    <QuantityManager
                      quantityDecreaseHandler={this.quantityDecreaseHandler}
                      quantityIncreaseHandler={this.quantityIncreaseHandler}
                      selectedQuantity={userSelections.quantity}
                      available={productQty}
                      quantityHandler={this.quantityHandler}
                      isEnabled={
                        productPrice.isAllSelected ||
                        !!productPrice.productPrice
                      }
                    />
                  </div>
                  <div className="col-12">
                    <div className="gx-mb-4">
                      <h6 className="">
                        Availibility : &nbsp;
                        <span
                          style={{ display: "inline-block", color: "#129c30" }}
                        >
                          {productQty && productQty !== 0 ? (
                            <div>{` ${productQty}`}</div>
                          ) : (
                            <div style={{ color: "red" }}>Out of stock</div>
                          )}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  style={{ float: "left" }}
                  className="d-flex flex-wrap justify-content-between"
                >
                  {IS_BUY_ITEMS ? (
                    <React.Fragment>
                      <Button
                        className="new-btn-clr"
                        style={{ width: "100px" }}
                        onClick={
                          () =>
                            this.getLocationDetails(
                              data,
                              productPrice,
                              productQty,
                              this.buyNowHandler,
                              "BUY_NOW"
                            )
                          // this.buyNowHandler(data, productPrice, productQty)

                          // disabled={
                          //   // !IS_ENABLED_SHIPPING_COST_API ||
                          //   shippingCost.loading ||
                          //   shippingCost.hasError
                        }
                      >
                        Buy now
                      </Button>
                      <Button
                        // className="primry-hvr"
                        type="primary"
                        style={{ width: "100px" }}
                        onClick={() =>
                          // this.addCartHandler(data, productPrice, productQty)
                          this.getLocationDetails(
                            data,
                            productPrice,
                            productQty,
                            this.addCartHandler,
                            "ADD_CART"
                          )
                        }
                        disabled={
                          !IS_ENABLED_SHIPPING_COST_API
                          //  ||
                          // shippingCost.loading ||
                          // shippingCost.hasError
                        }
                      >
                        Add to Cart
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() =>
                        contactSellerHandler(
                          sellerID,
                          currentRoute,
                          this.props.chatActions.selectedSellerHandler
                        )
                      }
                      disabled={
                        this.props.product.loading ||
                        this.props.product.hasError
                      }
                    >
                      Contact Seller
                    </Button>
                  )}
                  <span>
                    <button
                      className="like-button-3"
                      onClick={() =>
                        this.addWishlistHandler(data, productPrice)
                      }
                    >
                      {data && data.is_wishlisted ? (
                        <span className="like-clicked">
                          <i className="fas fa-heart like-icon-3"></i>
                        </span>
                      ) : (
                        <span className="like-unclicked">
                          <i className="fas fa-heart like-icon-3"></i>
                        </span>
                      )}
                    </button>
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={24}
              sm={24}
              xs={24}
              style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <ProductRightBar
                selectedShippingMethod={selectedShippingMethod}
                locationOnChangeHandler={this.locationOnChangeHandler}
                locationFilterHandler={this.locationFilterHandler}
              />
            </Col>
          </Row>
        </Card>
        <LocationPickerModal
          visible={this.state.LocationPickerModalVisible}
          locationOnChangeHandler={this.locationOnChangeHandler}
          shippingCost={shippingCost}
          toggleLocationPickerModalHandler={
            this.toggleLocationPickerModalHandler
          }
          buyNowHandler={this.buyNowHandler}
          addCartHandler={this.addCartHandler}
          item={this.state.selectedItem}
          productPriceObj={this.state.selectedProductPriceObj}
          productQty={this.state.selectedProductQty}
          operation={this.state.operation}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.Cart.myCart,
    product: state.Cart.product,
    shippingCost: state.Cart.shippingCost,
    userSelections: state.Cart.userSelections,
    domesticLocations: state.Domesticshipping.domesticLocations,
    selectedDomesticShippingLocation:
      state.Cart.selectedDomesticShippingLocation,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    chatActions: bindActionCreators(chatActions, dispatch),
    domesticShippingActions: bindActionCreators(
      domesticShippingActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DomesticProductInfo)
);

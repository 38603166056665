import React from "react";
import { Row, Col, Table, Popconfirm, message, Spin, Alert } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import ShippingInfoModal from "../../Shipping/ShippingInfoModal/ShippingInfoModal";
import QuantityManager from "../../../Common/QuantityManager/QuantityManager";
// import { profileActions } from "../ProfilePage/ducks";
import "./ShoppingCart.less";
import {
  getSelectedProductPrice,
  checkIfIsSelectedItems,
  toTitleCase,
  getDefaultShippingAddress,
} from "../../../../util/services";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import { SHOPPING_CART } from "../../../../constants/Utilities";
import { DEFAULT_CURRENCY, IS_DOMESTIC_MODE } from "../../../../constants";
import LocationPickerModal from "../../SingleProduct/productInfo/LocationPickerModal";

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the CRM column
      loading: false,
      myCartObj: null,
      LocationPickerModalVisible: false,
    };

    //only for web
    this.columns = [
      {
        title: "Product(s)",
        width: "120px",
        className: "p-0",

        render: (text, record) => (
          <img
            alt="avatar"
            src={record.images && record.images[0]}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          />
        ),
      },
      {
        title: "Details",
        render: (text, record, index) => {
          var selectedShippingMethod = record.selectedShippingMethod;
          var selectedProductVariations = getSelectedProductPrice(
            record.variations,
            record.userSelections.variations,
            record
          );
          var userSelectionsVariationsArray =
            record.userSelections && record.userSelections.variations
              ? Object.keys(record.userSelections.variations).map((key) => [
                  key,
                  record.userSelections.variations[key],
                ])
              : [];

          return (
            <div key={index}>
              {record.product_unvailable &&
                selectedProductVariations &&
                record.userSelections.quantity >
                  selectedProductVariations.qty && (
                  <div>
                    <Alert message={record.message} type="warning" showIcon />
                  </div>
                )}
              <div className="new-order-crd">
                <div>
                  <b>Seller : </b>
                  {record.seller ? (
                    <Link
                      className="crd-fnt-jr"
                      to={`/seller/${record.seller.id}`}
                    >
                      {record.seller && record.seller.name}
                    </Link>
                  ) : null}
                </div>
                <div>
                  <b>Product Name : </b>
                  {record.name ? (
                    <Link className="crd-fnt-jr" to={`/product/${record.id}`}>
                      {record.name}
                    </Link>
                  ) : null}
                </div>

                <div>
                  {userSelectionsVariationsArray &&
                    userSelectionsVariationsArray.map((variation, i) => {
                      return (
                        <h6 className="mb-1" style={{ fontSize: "12px" }}>
                          <b> {toTitleCase(variation[0])} </b> : {variation[1]}
                        </h6>
                      );
                    })}
                </div>

                <div>
                  <b>Price : </b>
                  {selectedProductVariations ? (
                    <span>
                      <span style={{ color: "#ff6900" }}>
                        {selectedProductVariations
                          ? `${DEFAULT_CURRENCY} ${selectedProductVariations.price}`
                          : null}
                      </span>
                      &nbsp;
                      {record.discount_percentage !== 0 ? (
                        <del className="text-muted">{`${DEFAULT_CURRENCY} ${selectedProductVariations.old_price}`}</del>
                      ) : null}
                      &nbsp; &nbsp;
                      <span style={{ color: "#129c30", fontSize: "12px" }}>
                        {record.discount_percentage !== 0
                          ? `${record.discount_percentage} % off`
                          : null}
                      </span>
                    </span>
                  ) : (
                    <span>
                      <span style={{ color: "#ff6900" }}>{"N/A"}</span>
                    </span>
                  )}
                </div>
              </div>

              <Row>
                <Col
                  xl={14}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="gx-d-none gx-d-sm-block"
                >
                  <br />
                  <div className="ml-2">
                    <React.Fragment>
                      <div
                        className="crd-fnt-jr"
                        onClick={() => this.toggleLocationPickerModalHandler()}
                      >
                        {this.props.selectedDomesticShippingLocation?.data
                          ? `Delivered to ${this.props.selectedDomesticShippingLocation?.data?.district} -
                      ${this.props.selectedDomesticShippingLocation?.data?.city}`
                          : "Set Delivery Location"}
                        <i
                          className="fad fa-angle-right ml-1"
                          style={{ fontSize: "20px", verticalAlign: "middle" }}
                        ></i>
                      </div>
                    </React.Fragment>
                    <small>
                      <ShippingInfoModal
                        product_id={record.id}
                        data={record}
                        selectedShippingMethod={selectedShippingMethod}
                      />
                    </small>
                  </div>
                </Col>
                <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                  <br />
                  <QuantityManager
                    quantityDecreaseHandler={() =>
                      this.quantityDecreaseHandler(
                        record,
                        selectedProductVariations.qty,
                        index
                      )
                    }
                    quantityIncreaseHandler={() =>
                      this.quantityIncreaseHandler(
                        record,
                        selectedProductVariations.qty,
                        index
                      )
                    }
                    selectedQuantity={
                      record.userSelections && record.userSelections.quantity
                    }
                    available={
                      selectedProductVariations && selectedProductVariations.qty
                    }
                    quantityHandler={(quantity) =>
                      this.quantityHandler(
                        quantity,
                        record,
                        selectedProductVariations.qty,
                        index
                      )
                    }
                    isCart={true}
                  />
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        title: "Action",
        width: "80px",
        dataIndex: "",
        className: "pl-0",
        key: "x",
        render: (text, record, index) => (
          <Popconfirm
            title="Are you sure delete?"
            onConfirm={() =>
              this.cartItemDeleteHandler(
                record,
                index,
                this.state.selectedRowKeys
              )
            }
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <div style={{ fontSize: "30px" }}>
              <i className="fad fa-trash-alt ml-2 tsh-clr"></i>
            </div>
          </Popconfirm>
        ),
      },
    ];

    //only for mobile
    this.mobColumns = [
      {
        title: "Product(s)",
        // width: "190px",
        render: (text, record, index) => {
          var selectedShippingMethod = record.selectedShippingMethod;

          var selectedProductVariations = getSelectedProductPrice(
            record.variations,
            record.userSelections.variations,
            record
          );
          var userSelectionsVariationsArray =
            record.userSelections && record.userSelections.variations
              ? Object.keys(record.userSelections.variations).map((key) => [
                  key,
                  record.userSelections.variations[key],
                ])
              : [];
          return (
            <div key={index}>
              {record.product_unvailable && (
                <div>
                  <Alert message={record.message} type="warning" showIcon />
                </div>
              )}

              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="mb-3" style={{ textAlign: "center" }}>
                    <img
                      alt="avatar"
                      src={record.images && record.images[0]}
                      style={{
                        maxWidth: "150px",
                        minHeight: "150px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </Col>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="new-order-crd mb-3">
                    <div>
                      <span>
                        <b>Seller : </b>
                      </span>
                      <span>
                        {record.seller ? (
                          <Link
                            className="crd-fnt-jr"
                            to={`/seller/${record.seller.id}`}
                          >
                            {record.seller && record.seller.name}
                          </Link>
                        ) : null}
                      </span>
                    </div>

                    <div className="mt-1">
                      <span>
                        <b>Product : </b>
                      </span>
                      <span>
                        {record.name ? (
                          <Link
                            className="crd-fnt-jr"
                            to={`/product/${record.id}`}
                          >
                            {record.name}
                          </Link>
                        ) : null}
                      </span>
                    </div>

                    <div>
                      {userSelectionsVariationsArray &&
                        userSelectionsVariationsArray.map((variation, i) => {
                          return (
                            <h6 className="mb-1" style={{ fontSize: "12px" }}>
                              <b> {toTitleCase(variation[0])} </b> :{" "}
                              {variation[1]}
                            </h6>
                          );
                        })}
                    </div>

                    <div className="mt-1 mb-0">
                      <b>Price : </b>
                      {selectedProductVariations ? (
                        <span>
                          <span style={{ color: "#ff6900" }}>
                            {selectedProductVariations
                              ? `${DEFAULT_CURRENCY} ${selectedProductVariations.price}`
                              : null}
                          </span>
                          &nbsp;
                          {record.discount_percentage !== 0 ? (
                            <del className="text-muted">{`${DEFAULT_CURRENCY} ${selectedProductVariations.old_price}`}</del>
                          ) : null}
                          &nbsp; &nbsp;
                          <span style={{ color: "#129c30", fontSize: "12px" }}>
                            {record.discount_percentage !== 0
                              ? `${record.discount_percentage} % off`
                              : null}
                          </span>
                        </span>
                      ) : (
                        <span>
                          <span style={{ color: "#ff6900" }}>{"N/A"}</span>
                        </span>
                      )}
                    </div>
                  </div>
                </Col>

                {record.product_unvailable && (
                  <div>
                    <Alert message={record.message} type="warning" showIcon />
                  </div>
                )}

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div style={{ textAlign: "center" }}>
                    <QuantityManager
                      quantityDecreaseHandler={() =>
                        this.quantityDecreaseHandler(
                          record,
                          selectedProductVariations.qty,
                          index
                        )
                      }
                      quantityIncreaseHandler={() =>
                        this.quantityIncreaseHandler(
                          record,
                          selectedProductVariations.qty,
                          index
                        )
                      }
                      selectedQuantity={
                        record.userSelections && record.userSelections.quantity
                      }
                      available={
                        selectedProductVariations &&
                        selectedProductVariations.qty
                      }
                      quantityHandler={(quantity) =>
                        this.quantityHandler(
                          quantity,
                          record,
                          selectedProductVariations.qty,
                          index
                        )
                      }
                      isCart={true}
                    />
                  </div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="mb-3 mt-2" style={{ textAlign: "center" }}>
                    <small>
                      <ShippingInfoModal
                        product_id={record.id}
                        data={record}
                        selectedShippingMethod={selectedShippingMethod}
                      />
                    </small>
                  </div>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Popconfirm
                    title="Are you sure delete?"
                    onConfirm={() => this.cartItemDeleteHandler(record)}
                    onCancel={() => console.log("cancel")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span style={{ fontSize: "30px" }} className="gx-link">
                      <div
                        className="dtlbtnCus pb-2"
                        style={{ textAlign: "center" }}
                      >
                        <i className="fad fa-trash-alt ml-2 tsh-clr"></i>
                      </div>
                    </span>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          );
        },
      },
    ];
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      this.props.profileActions.getShippingAddress();
    }
  }

  //Increase the product quantity
  quantityIncreaseHandler = (cartItem, avalability, index) => {
    const { selectedItems, shippingAddress } = this.props;
    const { userSelections } = cartItem;
    const { quantity } = userSelections;
    let key = "quantity";
    let value = quantity + 1;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else if (value > avalability) {
      message.info(
        avalability === 0 ? `Product is sold out` : `Only ${avalability} `
      );
    } else {
      let updatedSelectedItems = checkIfIsSelectedItems(
        selectedItems,
        cartItem.id,
        key,
        value
      );
      this.props.cartActions.productUserSelectionsHandler({
        key: key,
        value: value,
        isCart: true,
        product_id: cartItem.id,
        updatedIndex: index,
      });

      if (updatedSelectedItems) {
        if (updatedSelectedItems) {
          let quantityChangedProoduct = {
            key: key,
            value: value,
            isCart: true,
            product_id: cartItem.id,
            updatedIndex: index,
          };

          // get user default address
          let defaultShippingAddress =
            getDefaultShippingAddress(shippingAddress);

          // call order summery API and update
          this.props.cartActions.productQuantityChangeInCart({
            quantityChangedProoduct,
            updatedSelectedItems,
            defaultShippingAddress,
            cartType: SHOPPING_CART,
          });
        }
      }
    }
  };

  //Decrease the product quantity
  quantityDecreaseHandler = (cartItem, avalability, index) => {
    const { selectedItems, shippingAddress } = this.props;
    const { userSelections } = cartItem;
    const { quantity } = userSelections;
    let key = "quantity";
    let value = quantity - 1;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else {
      let updatedSelectedItems = checkIfIsSelectedItems(
        selectedItems,
        cartItem.id,
        key,
        value
      );

      // updated reducer based on user input
      this.props.cartActions.productUserSelectionsHandler({
        key: key,
        value: value,
        isCart: true,
        product_id: cartItem.id,
        updatedIndex: index,
      });

      if (updatedSelectedItems) {
        let quantityChangedProoduct = {
          key: key,
          value: value,
          isCart: true,
          product_id: cartItem.id,
          updatedIndex: index,
        };

        // get user default address
        let defaultShippingAddress = getDefaultShippingAddress(shippingAddress);

        // call order summery API and update
        this.props.cartActions.productQuantityChangeInCart({
          quantityChangedProoduct,
          updatedSelectedItems,
          defaultShippingAddress,
          cartType: SHOPPING_CART,
        });
      }
    }
  };

  //Quantity handler
  quantityHandler = (quantity, avalability, index) => {
    let value = quantity;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else if (value > avalability) {
      message.info(`Only ${avalability} `);
    } else {
      this.setState({ selectedQuantity: quantity });
    }
  };

  //Delete in the cart item
  cartItemDeleteHandler = (record, index, selectedKeys) => {
    const { selectedItems, shippingAddress } = this.props;
    let selectedProducts = selectedItems.selectedRows;

    // get user default address
    let defaultShippingAddress = getDefaultShippingAddress(shippingAddress);

    // this.props.cartActions.removeCartItem({ record, selectedProducts });
    this.props.cartActions.removeProductFromCart({
      record,
      selectedProducts,
      defaultShippingAddress,
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys });
  };

  toggleLocationPickerModalHandler = () => {
    this.setState({
      LocationPickerModalVisible: !this.state.LocationPickerModalVisible,
    });
  };

  locationOnChangeHandler = (selectedLocation) => {
    console.log(
      "🚀 ~ file: DomesticProductInfo.jsx ~ line 1172 ~ DomesticProductInfo ~ .map ~ selectedLocation",
      selectedLocation
    );

    const deliveryAreaId = selectedLocation.id;
    const district = selectedLocation.district;
    const city = selectedLocation.city;

    if (deliveryAreaId) {
      // this.props.cartActions.getShippingCost({
      //   id: product.id,
      //   // isCart: true,
      //   deliveryAreaId: deliveryAreaId,
      // });
      this.setState({
        userDomesticLocation: `${district} - ${city}`,
      });
    }
  };

  render() {
    const { myCart, shippingAddress, checkOrderQuantity, shippingCost } =
      this.props;
    const rowSelection = {
      getCheckboxProps: (record) => {
        return {
          disabled: record.product_unvailable,
        };
      },
      onChange: (selectedRowKeys, selectedRows) => {
        var selectedLocation = null;
        if (IS_DOMESTIC_MODE) {
          var selectedLocationString = localStorage.getItem("selectedLocation");
          if (!!selectedLocationString) {
            selectedLocation = JSON.parse(selectedLocationString);
          }
        }

        this.props.cartActions.productsSelectInCart({
          selectedRows: selectedRows,
          shippingDetails: null /* buyer location get on IP address */,
          delivery_area_id:
            this.props.selectedDomesticShippingLocation.data?.id ||
            selectedLocation?.id ||
            null,
        });

        this.setState({ selectedRowKeys });
      },
    };
    return (
      <Spin spinning={shippingAddress.pending || checkOrderQuantity.pending}>
        <div
          className="gx-card p-4"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <h5 className="mb-3 ml-2">Shopping Cart</h5>

          <div className="gx-card p-4 mb-0">
            <Table
              className="tbl-eff-jr mb-0 cart-in-web"
              rowSelection={rowSelection}
              columns={this.columns}
              dataSource={myCart.items}
              rowKey="key"
            />

            <Table
              className="tbl-eff-jr mb-0 cart-in-mob"
              rowSelection={rowSelection}
              columns={this.mobColumns}
              dataSource={myCart.items}
              rowKey="key"
            />
          </div>
        </div>
        <LocationPickerModal
          visible={this.state.LocationPickerModalVisible}
          // locationOnChangeHandler={this.locationOnChangeHandler}
          shippingCost={shippingCost}
          toggleLocationPickerModalHandler={
            this.toggleLocationPickerModalHandler
          }
          locationOnChangeHandler={this.locationOnChangeHandler}
          // buyNowHandler={this.buyNowHandler}
          // addCartHandler={this.addCartHandler}
          // item={this.state.selectedItem}
          // productPriceObj={this.state.selectedProductPriceObj}
          // productQty={this.state.selectedProductQty}
          // operation={this.state.operation}
          isCart={true}
        />
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    shippingAddress: state.Profile.shippingAddress,
    checkOrderQuantity: state.Cart.checkOrderQuantity,
    selectedDomesticShippingLocation:
      state.Cart.selectedDomesticShippingLocation,
    shippingCost: state.Cart.shippingCost,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
);

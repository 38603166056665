import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import "./firebase/firebase";
import "react-datepicker/dist/react-datepicker.css";

// layouts
import DefaultLayout from "./layouts/DefaultLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import HomeLayout from "./layouts/HomeLayout";
import Profilelayout from "./layouts/Profilelayout";
import Accountlayout from "./layouts/Accountlayout";
import Orderlayout from "./layouts/Orderlayout";
import Messagelayout from "./layouts/Messagelayout";
import OrderTracklayout from "./layouts/OrderTracklayout";
import AuthLayout from "./layouts/AuthLayout";
import CheckoutLayout from "./layouts/CheckoutLayout";
import DashboardProfileLayout from "./layouts/DashboardProfileLayout";
import DashbboardAcountGenaral from "./layouts/DashboardAccountGenaralLayout";
import ResolutionCenterLayout from "./layouts/ResolutionCenterLayout";
import CustomerServiceLayout from "./layouts/CustomerServiceLayout";

// views
import Views from "./modules/Views";
import SellerDashboardView from "./modules/SellerDashboard/SellerDashboardView";
import {
  IS_DISABLED_PAYMENT,
  IS_ENABLED_FAQ_MODULE,
  IS_ENABLED_SUPPORT_CENTER_MODULE,
  TRADESBARN
} from "./constants";
import SupportCenterLayout from "./layouts/SupportCenterLayout";

// const loggedUser = Authorization([UserRole.SUPER_USER])

const routes = [
  //----------- seller --------------
  {
    path: "/seller/dashboard/productManagement/productList/:type",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ProductManagementView,
  },
  {
    path: "/seller/dashboard/resolution-center/open-case",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.DashboardResolutionCenterOpenCaseView,
  },
  {
    path: "/seller/dashboard/resolution-center/:type",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.DashboardResolutionCenterView,
  },
  {
    path: "/seller/dashboard/resolution-center/item/list",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.DashboardResolutionCaseListView,
  },

  {
    path: "/seller/dashboard/resolution-center/case/:id",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.DashboardSingleCaseView,
  },
  {
    path: "/seller/dashboard/productManagement/product/add",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AddProductView,
  },
  {
    path: "/seller/dashboard/product/update/:id",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AddProductView,
  },
  {
    path: "/seller/dashboard/productManagement/qnaManagement",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ProductQnAView,
  },
  {
    path: "/seller/dashboard/orderManagement/:type",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.OrderManagementView,
  },
  {
    path: "/seller/dashboard/userManagement/:type",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.UserManagementView,
  },
  {
    path: "/seller/dashboard/userManagement/:type/details/:mode/:id",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.UserDetailsView,
  },
  {
    path: "/seller/dashboard/userManagement/:type/:mode",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.UserDetailsView,
  },
  {
    path: "/seller/dashboard/productReview",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ProductReviewView,
  },
  {
    path: "/seller/dashboard/sellerReview",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.SellerReviewView,
  },

  {
    path: "/seller/dashboard/profile/general/selleraccount",
    layout: DashbboardAcountGenaral,
    exact: true,
    component: SellerDashboardView.AccountGenaralSellerAccountView,
  },
  {
    path: "/seller/dashboard/profile/general/businessinformation",
    layout: DashbboardAcountGenaral,
    exact: true,
    component: SellerDashboardView.AccountGenaralBusinessInfoView,
  },
  {
    path: "/seller/dashboard/profile/general/bankaccount",
    layout: DashbboardAcountGenaral,
    exact: true,
    component: SellerDashboardView.AccountGenaralBankAccountView,
  },
  {
    path: "/seller/dashboard/profile/general/address",
    layout: DashbboardAcountGenaral,
    exact: true,
    component: SellerDashboardView.AccountGenaralAddressView,
  },
  {
    path: "/seller/dashboard/profile/sellerlogo",
    layout: DashboardProfileLayout,
    exact: true,
    component: SellerDashboardView.AccountSellerLogoView,
  },
  {
    path: "/seller/dashboard/profile/invoicenumber",
    layout: DashboardProfileLayout,
    exact: true,
    component: SellerDashboardView.AccountInvoiceNumberView,
  },
  {
    path: "/seller/dashboard/profile/shipping",
    layout: DashboardProfileLayout,
    exact: true,
    component: SellerDashboardView.AccountShippingView,
  },
  {
    path: "/seller/dashboard/message-center",
    layout: DashboardLayout,
    exact: true,
    component: Views.ChatView,
  },
  {
    path: "/seller/dashboard/message-center/:userID",
    layout: DashboardLayout,
    exact: true,
    component: Views.ChatView,
  },
  {
    path: "/seller/dashboard/finance/order-overview",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AccountFinanceOrderOverviewView,
  },
  {
    path: "/seller/dashboard/finance/account-statement",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AccountFinanceAccountStatementView,
  },
  {
    path: "/seller/dashboard/finance/transaction-overview",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AccountFinanceTransactionOverviewView,
  },
  {
    path: "/seller/dashboard/addressBook",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.AddressBookView,
  },
  {
    path: "/seller/dashboard/profile/account-settings",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.SellerAccountSettingsView,
  },

  {
    path: "/seller/dashboard/report/:type",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ReportManagementView,
  },

  {
    path: "/seller/dashboard/applyTopBrand",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ApplyTopBrandView,
  },
  {
    path: "/seller/dashboard/ApplyFeaturedProduct",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ApplyFeatureProductView,
  },
  {
    path: "/seller/dashboard/ApplyBoostProduct",
    layout: DashboardLayout,
    exact: true,
    component: SellerDashboardView.ApplyBoostProductView,
  },

  {
    path: "/dashboard",
    layout: DashboardLayout,
    exact: true,
    component: Views.DashboardView,
  },
  //----------- end seller --------------

  //----------- buyer --------------

  //----------- support-center --------------

  {
    path: "/support-center/buyer-registration",
    layout: SupportCenterLayout,
    exact: true,
    component: Views.BuyerRegistrationView,
    isDeactive: !IS_ENABLED_SUPPORT_CENTER_MODULE,
  },
  {
    path: "/support-center/seller-registration",
    layout: SupportCenterLayout,
    exact: true,
    component: Views.SellerRegistrationView,
    isDeactive: !IS_ENABLED_SUPPORT_CENTER_MODULE,
  },
  {
    path: "/support-center/product-listing",
    layout: SupportCenterLayout,
    exact: true,
    component: Views.ProductListingView,
    isDeactive: !IS_ENABLED_SUPPORT_CENTER_MODULE,
  },
  {
    path: "/support-center/resolution-center",
    layout: SupportCenterLayout,
    exact: true,
    component: Views.ResolutionCenterHelpView,
    isDeactive: !IS_ENABLED_SUPPORT_CENTER_MODULE,
  },

  // FAQ SECTION
  {
    path: "/faq-section",
    layout: DefaultLayout,
    exact: true,
    component: Views.FAQView,
    isDeactive: !IS_ENABLED_FAQ_MODULE,
  },

  {
    path: "/customer-service/company-info-and-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.CompanyInforAndPolicyView,
  },
  {
    path: "/customer-service/accepted-payments-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.AcceptedPaymentsPolicyView,
  },
  {
    path: "/customer-service/intellectual-property-pules",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.IntellectualPropertyRulesView,
  },
  {
    path: "/customer-service/real-estate-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.RealEstatePolicyView,
  },
  {
    path: "/customer-service/tax-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.TaxPolicyView,
  },
  {
    path: "/customer-service/identity-screening-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.IdentityScreeningPolicyView,
  },
  {
    path: "/customer-service/fraud-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.FraudPolicyView,
  },
  {
    path: "/customer-service/final-value-fees",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.FinalValueFeesView,
  },
  {
    path: "/customer-service/online-advertising-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: Views.OnlineAdvertisingPolicyView,
  },
  {
    path: "/customer-service/privacy-policy",
    layout: CustomerServiceLayout,
    exact: true,
    component: process.env.REACT_APP_BUSINESS_NAME === TRADESBARN ? Views.PrivacyPolicyView : Views.PrivacyPolicyViewOnShop,
  },
  {
    path: "/customer-service/terms-and-conditions",
    layout: CustomerServiceLayout,
    exact: true,
    component: process.env.REACT_APP_BUSINESS_NAME === TRADESBARN ? Views.TermAndConditionsView : Views.TermAndConditionsViewOnShop,
  },
  {
    path: "/search/",
    layout: DefaultLayout,
    exact: true,
    component: Views.AllProductsView,
  },
  {
    path: "/search/:categorylvl0/",
    layout: DefaultLayout,
    exact: true,
    component: Views.AllProductsView,
  },
  {
    path: "/search/:categorylvl0/:categorylvl1",
    layout: DefaultLayout,
    exact: true,
    component: Views.AllProductsView,
  },
  {
    path: "/search/:categorylvl0/:categorylvl1/:categorylvl2",
    layout: DefaultLayout,
    exact: true,
    component: Views.AllProductsView,
  },
  {
    path: "/cart",
    layout: DefaultLayout,
    exact: true,
    component: Views.CartView,
    isDeactive: IS_DISABLED_PAYMENT,
  },
  {
    path: "/checkout/fail",
    layout: CheckoutLayout,
    exact: true,
    component: Views.CheckoutFailView,
    isDeactive: IS_DISABLED_PAYMENT,
  },
  {
    path: "/help/about-us",
    layout: DefaultLayout,
    exact: true,
    component: Views.UserAgreementView,
  },
  {
    path: "/checkout/success",
    layout: CheckoutLayout,
    exact: true,
    component: Views.CheckoutSuccessView,
    isDeactive: IS_DISABLED_PAYMENT,
  },
  {
    path: "/checkout",
    layout: CheckoutLayout,
    exact: true,
    component: Views.CheckoutView,
    isDeactive: IS_DISABLED_PAYMENT,
  },
  {
    path: "/product/:id",
    layout: DefaultLayout,
    exact: true,
    component: Views.SingleProductView,
  },
  {
    path: "/profile/account",
    layout: Profilelayout,
    exact: true,
    component: Views.ProfileView,
  },
  {
    path: "/login",
    layout: AuthLayout,
    exact: true,
    component: Views.LoginView,
  },
  {
    path: "/register",
    layout: AuthLayout,
    exact: true,
    component: Views.LoginView,
  },
  {
    path: "/forgot-password",
    layout: AuthLayout,
    exact: true,
    component: Views.ForgetPasswordView,
  },
  {
    path: "/verify",
    layout: AuthLayout,
    exact: true,
    component: Views.VerifyResetPasswordView,
  },
  {
    path: "/privacy-policy",
    layout: DefaultLayout,
    exact: true,
    component: Views.PolicyView,
  },
  {
    path: "/seller/:sellerId",
    layout: DefaultLayout,
    exact: true,
    component: Views.SellerStoreView,
  },
  {
    path: "/wishlist",
    layout: Accountlayout,
    exact: true,
    component: Views.WishlistView,
  },
  {
    path: "/chat",
    layout: Messagelayout,
    exact: true,
    component: Views.ChatView,
  },
  {
    path: "/chat/:userID",
    layout: Messagelayout,
    exact: true,
    component: Views.ChatView,
  },
  {
    path: "/account",
    layout: Accountlayout,
    exact: true,
    component: Views.AccountView,
  },
  {
    path: "/account/change-password",
    layout: Accountlayout,
    exact: true,
    component: Views.ChangePassword,
  },
  {
    path: "/my-profile",
    layout: Accountlayout,
    exact: true,
    component: Views.MybarnView,
  },
  {
    path: "/order/delete",
    layout: Orderlayout,
    exact: true,
    component: Views.DeleteOrderView,
  },
  {
    path: "/order/address",
    layout: Orderlayout,
    exact: true,
    component: Views.ShippingAddressView,
  },
  // {
  //   path: "/order/cards",
  //   layout: Orderlayout,
  //   exact: true,
  //   component: Views.CardAddView,
  // },
  {
    path: "/payment-settings",
    layout: Orderlayout,
    exact: true,
    component: Views.CardAddView,
  },
  {
    path: "/shipping-address",
    layout: Orderlayout,
    exact: true,
    component: Views.ShippingAddressView,
  },
  {
    path: "/chat/notification",
    layout: Messagelayout,
    exact: true,
    component: Views.NatificationView,
  },
  {
    path: "/order/track",
    layout: OrderTracklayout,
    exact: true,
    component: Views.OrderTrackView,
  },
  {
    path: "/orders/:type",
    layout: Orderlayout,
    exact: true,
    component: Views.OrderView,
  },
  {
    path: "/Error",
    layout: DashboardLayout,
    exact: true,
    component: Views.ErrorView,
  },

  {
    path: "/resolution/case/:id",
    layout: ResolutionCenterLayout,
    exact: true,
    component: Views.SingleCaseView,
  },

  {
    path: "/resolution/report/detail",
    layout: ResolutionCenterLayout,
    exact: true,
    component: Views.ReportItemConfirmView,
  },
  {
    path: "/resolution/report",
    layout: ResolutionCenterLayout,
    exact: true,
    component: Views.ReportItemView,
  },
  {
    path: "/help/seller",
    layout: DefaultLayout,
    exact: true,
    component: Views.SellerHelpView,
  },
  {
    path: "/help/buyer",
    layout: DefaultLayout,
    exact: true,
    component: Views.BuyerHelpView,
  },
  {
    path: "/account/seller",
    layout: DefaultLayout,
    exact: true,
    component: Views.SellerView,
  },
  {
    path: "/account/seller/register",
    layout: DefaultLayout,
    exact: true,
    component: Views.SellerRegisterView,
  },
  {
    path: "/resolution-center/:type",
    layout: ResolutionCenterLayout,
    exact: true,
    component: Views.ResolutionCenterView,
  },
  {
    path: "/mobileApp",
    layout: DefaultLayout,
    exact: true,
    component: Views.MobileAppView,
  },
  {
    path: "/help-and-contact",
    layout: DefaultLayout,
    exact: true,
    component: Views.HelpPageView,
  },
  {
    path: "/term-and-conditions",
    layout: DefaultLayout,
    exact: true,
    component: Views.TermAndConditionsView,
  },
  //HomeView should be last one
  {
    path: "/",
    layout: HomeLayout,
    exact: true,
    component: Views.HomeView,
  },

  {
    path: "/not-fount-page",
    layout: DefaultLayout,
    exact: true,
    component: Views.PageNotFoundView,
  },
  //should be last one
  {
    layout: DefaultLayout,
    component: Views.PageNotFoundView,
  },
];

const RouterSwitch = ({location}) => (
  <Switch>
    {routes
      .filter((route) => !route.isDeactive)
      .map((route) => {
        return (
          <Route
            key={location.pathname}
            exact={route.exact}
            path={route.path}
            component={route.layout(route.component)}
          />
        );
      })}
  </Switch>
);

export default withRouter(RouterSwitch);

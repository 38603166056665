import React, { Component } from "react";
import { reduxForm, getFormValues } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, Card, Col, Row, Spin, Alert } from "antd";
import UploadPictures from "../../../../components/Seller/ProductManagement/UploadPictures/UploadPictures";
import { productManagementActions } from "../ducks";
// import VariationPhotos from "../../../../components/Seller/ProductManagement/VariationPhotos/VariationPhotos"; //Tempory removed this feature
import { dashboardProfileActions } from "../../SellerAccount/ducks";
import BasicInfo from "./BasicInfo";
// import ProductSpec from "./ProductSpec";
import ProductDescription from "./ProductDescription";
import PriceAndStock from "./PriceAndStock";
import Services from "./Services";
import PackageDetails from "./PackageDetails";
import ShippingDetails from "./ShippingDetails";
import DomesticShipping from "./DomesticShipping";
import InternationalShipping from "./InternationalShipping";
import ReturnPolicies from "./ReturnPolicies";
import validate from "./validate";
import { initializeObj } from "../../../../constants/FormUtility";

import "./AddProduct.less";
import { extraInfoDetails } from "./ExtraInfoDetails";
import ExtraInfo from "./ExtraInfo";
import { scrollIntoFirstError } from "../../../../util/FormErrorNavigator";
import {
  IS_DOMESTIC_MODE,
  IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS,
  IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD,
  IS_ENABLED_ADD_PRODUCT_FORM_SHIPPING_DETAILS,
  IS_INTERNATIONAL_SHIPPING,
} from "../../../../constants";

const combinations = (variations) => {
  return variations.reduce((a, b) =>
    a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
  );
};
class AddPoduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "Add Product",
      previewVisible: false,
      previewImage: "",
      getProduct: null,
      imageList: [],
      variationImageList: [],
      variationTableDto: false,
      variations: [],
      enableVariation: false,
      selectedVariationOption: "default",
      productVariations: [{ name: "", mobile: "" }],
      variationImagesObj: null,
      sellerDetails: null,
      fileList: [],
    };
  }

  componentDidMount() {
    const {
      productManagementActions,
      productCategories,
      match,
      shippingMethodList,
      dashboardProfileActions,
      sellerDetails,
    } = this.props;
    const { path } = match;
    shippingMethodList.loading && productManagementActions.getShippingMethod();

    var mode = "";
    if (path === "/seller/dashboard/product/update/:id") {
      var id = match.params && match.params.id;
      mode = "UPDATE";
      if (productCategories.loading) {
        productManagementActions.getProductCategories({ mode: mode, id: id });
      } else {
        productManagementActions.getProduct(id);
      }
    } else {
      mode = "CREATE";
      productCategories.loading &&
        productManagementActions.getProductCategories({ mode: mode });
      sellerDetails.loading && dashboardProfileActions.getSellerDetails();
      this.props.initialize(initializeObj);
    }
    this.setState({
      mode,
    });
  }

  componentWillUnmount() {
    this.clearState();
    this.props.productManagementActions.clearProductImages();
  }
  clearState = () => {
    this.setState({
      form: "Add Product",
      previewVisible: false,
      previewImage: "",
      getProduct: null,
      imageList: [],
      variationImageList: [],
      variationTableDto: false,
      variations: [],
      enableVariation: false,
      selectedVariationOption: "default",
      productVariations: [{ name: "", mobile: "" }],
      variationImagesObj: null,
      sellerDetails: null,
      fileList: [],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { getProduct, fieldValues, sellerDetails } = this.props;
    var variations = fieldValues && fieldValues.variations;
    var prevVariations =
      prevProps.fieldValues && prevProps.fieldValues.variations;
    if (
      getProduct &&
      getProduct.data &&
      getProduct.data.data &&
      JSON.stringify(getProduct) !== JSON.stringify(prevProps.getProduct)
    ) {
      var specifications = Object.keys(getProduct.data.data.item_specs).map(
        (key) => {
          return { label: key, values: getProduct.data.data.item_specs[key] };
        }
      );
      //variation array here
      var productVariations = getProduct.data.data.variations;
      var variationsTable = {};
      if (Array.isArray(productVariations)) {
        // debugger;
        variationsTable = productVariations.reduce((res, variation, idx) => {
          Object.keys(variation).forEach(
            (key) => (res[`var:${idx}:${key}`] = variation[key])
          );
          return res;
        }, {});
      }

      var imageList = [];
      var variationImageList = [];

      getProduct.data.data.images.map((image, i) => {
        imageList.push({
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: image,
        });
        return null;
      });

      getProduct.data.data.images.map((image, i) => {
        variationImageList.push({
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: image,
        });
        return null;
      });

      let productData = getProduct.data.data;
      var receivedObj = {
        productName: productData.name,
        productCategory: productData.category_ids,
        productBrand: productData.brand,
        productCondition: productData.condition,
        specifications: specifications,
        productHighlight: productData.highlight,
        productDescripton: productData.description,
        variations: productData.variation_values,
        warranty: productData.warranty,
        name: productData.name,
        address1: productData.address.address1,
        address2: productData.address.address2,
        itemZipCode: productData.address.zip,
        itemCity: productData.address.city,
        state: productData.address.state,
        itemCountry: productData.address.country,
        phone: productData.address.phone,
        packageWieght: productData.weight,
        packageLength: productData.length,
        packageWidth: productData.width,
        packageHeight: productData.height,

        packageWieghtMeasurement: productData.weight_measurement,
        packageLengthMeasurement: productData.length_measurement,

        dangerousGoods: productData.dangerous_good_type,
        productPrice: productData.price,
        productStock: productData.qty,
        imageEnabledVariation: productData.image_enabled_variation,
        discount: productData.discount_percentage,
        domesticShippingMethod: productData.domestic_shipping_method,
        domesticShippingTrackingAvailable:
          productData.domestic_shipping_tracking_available
            ? "enable"
            : "disable",
        internationalShippingMethod: productData.international_shipping_method,
        internationalShippingPrice: productData.international_shipping_price,
        internationalShippingAdditionalPrice:
          productData.international_shipping_additional_price,
        internationalShippingTrackingAvailable:
          productData.international_shipping_tracking_available
            ? "enable"
            : "disable",
        excludeShippingLocations: productData.excluded_shipping_countries,
        shippingHandlingDays: productData.shipping_handling_days,
        returnAcceptedMethod: productData.return_accepted_method,
        isProductVariable:
          productData.variation_types.length !== 0 ? "enable" : "disable",
        ...variationsTable,
        packageType: productData.package_type,
        internationalShippingCarriers:
          productData.international_shipping_carriers,
        domesticShippingEstimatedDeliveryDaysMin:
          productData.domestic_shipping_estimated_delivery_days_min,
        domesticShippingEstimatedDeliveryDaysMax:
          productData.domestic_shipping_estimated_delivery_days_max,
        domesticShippingEstimatedDeliveryNote:
          productData.domestic_shipping_estimated_delivery_note,
        internationalShippingEstimatedDeliveryDaysMin:
          productData.international_shipping_estimated_delivery_days_min,
        internationalShippingEstimatedDeliveryDaysMax:
          productData.international_shipping_estimated_delivery_days_max,
        internationalShippingEstimatedDeliveryNote:
          productData.international_shipping_estimated_delivery_note,
        domesticShippingPrice: productData.domestic_shipping_price,
        domesticShippingAdditionalPrice:
          productData.domestic_shipping_additional_price,
      };

      this.props.initialize(receivedObj);

      variations = getProduct.data.data.variation_values;
      var imageEnabledVariation = getProduct.data.data.image_enabled_variation;
      var selectedVariationOption = null;
      variations &&
        variations.forEach((variation, i) => {
          if (variation.label === imageEnabledVariation) {
            selectedVariationOption = i;
          }
        });

      var variationImagesObj = getProduct.data.data.variation_images;

      //update variations images
      this.props.productManagementActions.handleVariationsImages(
        variationImagesObj
      );

      this.setState({
        getProduct: getProduct,
        imageList: imageList,
        variationImageList: variationImageList,
        productVariations: productVariations,
        selectedVariationOption: selectedVariationOption,
        variationImagesObj: variationImagesObj,
      });
    }
    if (
      Array.isArray(variations) &&
      JSON.stringify(variations) !== JSON.stringify(prevVariations)
    ) {
      const labels = variations.map((variation) => variation.label || "");
      var var_comb = [];
      if (variations.length !== 0) {
        var_comb = combinations(
          variations.map((variation) => variation.values || [])
        );
      }

      var variationTable_Dto = {};
      var_comb.forEach((comb1, i) => {
        if ((comb1 || "").constructor === Array) {
          comb1.forEach((comb2, j) => {
            variationTable_Dto[`var:${i}:${labels[j]}`] = comb2;
          });
        } else {
          variationTable_Dto[`var:${i}:${labels[0]}`] = var_comb[i];
        }
      });
    }
    if (
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      JSON.stringify(sellerDetails) !== JSON.stringify(prevState.sellerDetails)
    ) {
      let bussinessInfo =
        sellerDetails.data.data && sellerDetails.data.data.bussiness_info;
      let accountInfo =
        sellerDetails.data.data && sellerDetails.data.data.account_info;

      var initialize = {
        ...initializeObj,
        itemCountry: bussinessInfo && bussinessInfo.country,
        itemCity: bussinessInfo && bussinessInfo.city,
        itemZipCode: bussinessInfo && bussinessInfo.postal_code,
        name: bussinessInfo && bussinessInfo.legal_name,
        address1: bussinessInfo && bussinessInfo.address1,
        address2: bussinessInfo && bussinessInfo.address2,
        state: bussinessInfo && bussinessInfo.state,
        phone: accountInfo && accountInfo.phone,
      };
      if (IS_DOMESTIC_MODE) {
        initialize = {
          ...initialize,
          shippingHandlingDays: 3,
          packageWieght: 1,
          packageLength: 20,
          packageWidth: 20,
          packageHeight: 5,
        };
      }
      this.props.initialize(initialize);
      this.setState({
        sellerDetails: sellerDetails,
      });
    }
  }

  //product variations table add row
  handleAddRow = () => {
    const item = {
      name: "",
      mobile: "",
    };
    this.setState({
      productVariations: [...this.state.productVariations, item],
    });
  };

  //product variation table remove row
  handleRemoveSpecificRow = (idx) => {
    let productVariations = [...this.state.productVariations];
    productVariations.splice(idx, 1);

    const { fieldValues } = this.props;
    Object.keys(fieldValues).forEach((variation) => {
      const var_arr = variation.split(":");
      if (var_arr[0] === "var") {
        let [, i, label] = var_arr;
        if (variation.startsWith(`var:${idx}`)) delete fieldValues[variation];
        else if (+i > idx) {
          delete Object.assign(fieldValues, {
            [`var:${i - 1}:${label}`]: fieldValues[`var:${i}:${label}`],
          })[`var:${i}:${label}`];
        }
      }
    });

    this.setState({ productVariations });
  };

  /**
   * ---- Temparary Removed the feature
   * variation photos handler
   * @param {variation type} value
   */
  variationPhotosHandler = (value) => {
    const { fieldValues } = this.props;
    var variations = fieldValues && fieldValues.variations;

    var selectedVariationOption = null;
    variations &&
      variations.forEach((variation, i) => {
        if (variation.label === value) {
          selectedVariationOption = i;
        }
      });

    this.setState({
      selectedVariationOption,
    });
  };

  categoryChangeHandler = (value, selectedOptions, label) => {
    
  };

  handleSubmit = () => {
    const { fieldValues, match, productImages } = this.props;

    const { path } = match;
    var item_specs = {};
    var variation_types = [];

    if (fieldValues) {
      //product specification
      fieldValues &&
        Array.isArray(fieldValues.specifications) &&
        fieldValues.specifications.map((spec, i) => {
          item_specs[spec.label] = spec.values;
          return null;
        });

      //variation type
      fieldValues &&
        Array.isArray(fieldValues.variations) &&
        fieldValues.variations.map((variation, i) => {
          variation_types.push(variation.label);
          return null;
        });

      // variations structure space here
      const variations_mapped = [];
      Object.keys(fieldValues).forEach((variation) => {
        const var_arr = variation.split(":");
        if (var_arr[0] === "var") {
          let [, idx, label] = var_arr;
          idx = parseInt(idx);
          variations_mapped[idx] = variations_mapped[idx] || {};
          variations_mapped[idx][label] = fieldValues[variation];
        }
      });

      /**
       * final product variations -------------------
       * IF user change the attribute name then remove the olders name
       * compare with variation types
       */
      let finalVariations = variations_mapped.map((v) => {
        Object.keys(v).forEach((key) => {
          if (!variation_types.includes(key) && !["qty", "price"].includes(key))
            delete v[key];
        });
        return v;
      });

      //check dangeroues goods
      var is_dangerous_good =
        fieldValues.dangerousGoods && fieldValues.dangerousGoods.length === 0
          ? false
          : true;
      let categoryID =
        Array.isArray(fieldValues.productCategory) &&
        fieldValues.productCategory[fieldValues.productCategory.length - 1];

      let isCalculatedShipping =
        fieldValues.internationalShippingMethod === "calculated";

      let domesticShippingTrackingAvailable =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_TRACKING_FIELD
          ? fieldValues.domesticShippingMethod === "no_shipping"
            ? undefined
            : fieldValues.domesticShippingTrackingAvailable === "enable"
            ? true
            : false
          : undefined;

      let internationalShippingTrackingAvailable =
        fieldValues.internationalShippingMethod === "calculated" ||
        fieldValues.internationalShippingMethod === "no_shipping"
          ? undefined
          : fieldValues.internationalShippingTrackingAvailable === "enable"
          ? true
          : false;

      let internationalShippingCarriers =
        fieldValues.internationalShippingMethod === "calculated"
          ? fieldValues.internationalShippingCarriers
          : undefined;

      let domesticShippingEstimatedDeliveryDaysMin =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS
          ? fieldValues.domesticShippingMethod !== "no_shipping"
            ? +fieldValues.domesticShippingEstimatedDeliveryDaysMin
            : undefined
          : undefined;

      let domesticShippingEstimatedDeliveryDaysMax =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS
          ? fieldValues.domesticShippingMethod !== "no_shipping"
            ? +fieldValues.domesticShippingEstimatedDeliveryDaysMax
            : undefined
          : undefined;

      let domesticShippingEstimatedDeliveryNote =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS
          ? fieldValues.domesticShippingMethod !== "no_shipping"
            ? fieldValues.domesticShippingEstimatedDeliveryNote
              ? fieldValues.domesticShippingEstimatedDeliveryNote
              : undefined
            : undefined
          : undefined;

      let domesticShippingPrice =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS
          ? fieldValues.domesticShippingMethod === "flat_rated"
            ? fieldValues.domesticShippingPrice
            : undefined
          : undefined;

      let domesticShippingAdditionalPrice =
        IS_ENABLED_ADD_PRODUCT_FORM_DOMESTIC_ESTIMATE_SHIPPING_OPTIONS
          ? fieldValues.domesticShippingMethod === "flat_rated"
            ? fieldValues.domesticShippingAdditionalPrice
            : undefined
          : undefined;

      let isFillDeliveryDetails =
        fieldValues.internationalShippingMethod === "free_shipping" ||
        fieldValues.internationalShippingMethod === "flat_rated";

      let internationalShippingEstimatedDeliveryDaysMin = isFillDeliveryDetails
        ? +fieldValues.internationalShippingEstimatedDeliveryDaysMin
        : undefined;

      let internationalShippingEstimatedDeliveryDaysMax = isFillDeliveryDetails
        ? +fieldValues.internationalShippingEstimatedDeliveryDaysMax
        : undefined;

      let internationalShippingEstimatedDeliveryNote = isFillDeliveryDetails
        ? fieldValues.internationalShippingEstimatedDeliveryNote
          ? fieldValues.internationalShippingEstimatedDeliveryNote
          : undefined
        : undefined;

      let internationalShippingPrice =
        fieldValues.internationalShippingMethod === "flat_rated"
          ? fieldValues.internationalShippingPrice
          : undefined;

      let internationalShippingAdditionalPrice =
        fieldValues.internationalShippingMethod === "flat_rated"
          ? fieldValues.internationalShippingAdditionalPrice
          : undefined;

      let excludedShippingCountries =
        fieldValues.internationalShippingMethod === "no_shipping"
          ? undefined
          : fieldValues.excludeShippingLocations;

      let isVariations = fieldValues.isProductVariable === "enable";

      let qty = isVariations ? undefined : +fieldValues.productStock;
      let price = isVariations ? undefined : fieldValues.productPrice;
      let address = {
        name: isCalculatedShipping ? fieldValues.name : undefined,
        address1: isCalculatedShipping ? fieldValues.address1 : undefined,
        address2: isCalculatedShipping ? fieldValues.address2 : undefined,
        zip: fieldValues.itemZipCode,
        city: fieldValues.itemCity,
        state: isCalculatedShipping ? fieldValues.state : undefined,

        country: fieldValues.itemCountry,
        phone: isCalculatedShipping ? fieldValues.phone : undefined,
      };

      var productDto = {
        name: fieldValues.productName,
        brand: fieldValues.productBrand,
        condition: fieldValues.productCondition,
        category_id: categoryID,
        images: [...productImages.image_keys],
        item_specs: item_specs,
        highlight: fieldValues.productHighlight,
        description: fieldValues.productDescripton,
        warranty: fieldValues.warranty,
        address: IS_DOMESTIC_MODE ? undefined : address,
        package_type: fieldValues.packageType,
        weight: fieldValues.packageWieght,
        length: fieldValues.packageLength,
        width: fieldValues.packageWidth,
        height: fieldValues.packageHeight,
        weight_measurement: fieldValues.packageWieghtMeasurement,
        length_measurement: fieldValues.packageLengthMeasurement,
        is_dangerous_good: is_dangerous_good,
        dangerous_good_type: fieldValues.dangerousGoods,
        variation_types: variation_types,
        variations: finalVariations,
        discount_percentage: fieldValues.discount,
        price: price,
        qty: qty,
        image_enabled_variation: fieldValues.imageEnabledVariation,
        domestic_shipping_method: fieldValues.domesticShippingMethod,
        domestic_shipping_price: domesticShippingPrice,
        domestic_shipping_additional_price: domesticShippingAdditionalPrice,
        domestic_shipping_tracking_available: domesticShippingTrackingAvailable,
        international_shipping_method: fieldValues.internationalShippingMethod,
        international_shipping_price: internationalShippingPrice,
        international_shipping_additional_price:
          internationalShippingAdditionalPrice,
        international_shipping_tracking_available:
          internationalShippingTrackingAvailable,
        excluded_shipping_countries: excludedShippingCountries,
        shipping_handling_days: fieldValues.shippingHandlingDays,
        return_accepted_method: fieldValues.returnAcceptedMethod,
        international_shipping_carriers: internationalShippingCarriers,
        domestic_shipping_estimated_delivery_days_min:
          domesticShippingEstimatedDeliveryDaysMin,
        domestic_shipping_estimated_delivery_days_max:
          domesticShippingEstimatedDeliveryDaysMax,
        domestic_shipping_estimated_delivery_note:
          domesticShippingEstimatedDeliveryNote,
        international_shipping_estimated_delivery_days_min:
          internationalShippingEstimatedDeliveryDaysMin,
        international_shipping_estimated_delivery_days_max:
          internationalShippingEstimatedDeliveryDaysMax,
        international_shipping_estimated_delivery_note:
          internationalShippingEstimatedDeliveryNote,
      };
      if (path === "/seller/dashboard/product/update/:id") {
        var id = match.params && match.params.id;
        // let productUpdateDto = Object.assign({}, productDto);

        this.props.productManagementActions.updateProduct({
          id: id,
          productDto: productDto,
        });
      } else {
        this.props.productManagementActions.createProduct(productDto);
      }
    } else {
      
    }
  };

  internationalShippingTrackingAvailableHandler = () => {};

  render() {
    const {
      handleSubmit,
      fieldValues,
      productCategories,
      shippingMethodList,
      createProduct,
      updateProduct,
      getProduct,
      sellerDetails,
    } = this.props;
    const { imageList, mode, productVariations } = this.state;
    var variations = [];
    variations = fieldValues && fieldValues.variations;
    // var isNoVariations = true;
    // if (
    //   variations &&
    //   Array.isArray(variations) &&
    //   variations.length >= 1 &&
    //   variations[0] &&
    //   variations[0].values &&
    //   Array.isArray(variations[0].values) &&
    //   variations[0].values[0]
    // ) {
    //   isNoVariations = false;
    // }

    var isEnableProductVariable =
      fieldValues && fieldValues.isProductVariable === "enable" ? true : false;
    let pending =
      createProduct.pending ||
      updateProduct.pending ||
      getProduct.pending ||
      productCategories.pending ||
      shippingMethodList.pending ||
      sellerDetails.pending;

    if (this.props.fieldValues && this.props.fieldValues.variations) {
      this.props.fieldValues.variations.forEach((element) => {
        if (element.values === undefined) {
          element.values = [];
        }
      });
    }

    return (
      <Row>
        <Col span={24}>
          <div>
            <div style={{ paddingBottom: "20px" }}>
              <h3>{mode === "CREATE" ? "Add Product" : "Update Product"}</h3>
            </div>
            <Spin spinning={pending}>
              <Form
                onSubmit={handleSubmit(this.handleSubmit)}
                className="gx-signin-form gx-form-row0"
              >
                <Card className="gx-card">
                  {/* Basic Information */}
                  <BasicInfo
                    id="scroller"
                    productCategories={productCategories}
                    categoryChangeHandler={this.categoryChangeHandler}
                  />
                  {/* Upload Images */}
                  <div className="mb-3">
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="mb-3"
                    >
                      <h6 className="mb-0 mr-2">Product Gallery</h6>

                      <ExtraInfo
                        placement={"top"}
                        title={extraInfoDetails.productGallery}
                      />
                    </div>
                    <Card style={{ backgroundColor: "#fafafa" }}>
                      <Alert
                        message="Informational Notes"
                        description="An image should be below 5MB, We accept .jpeg .jpg .bmp .png images. To prevent your image from being subjected to cut corners, better to provide squared shape pictures."
                        type="info"
                        showIcon
                      />
                      <UploadPictures
                        imageList={imageList}
                        noOfPhotos={12}
                        isCrop={true}
                      />
                    </Card>
                  </div>
                  {/* Product Specifications */}
                  {/* <ProductSpec /> */} {/* Tempary removed 28/09/2021 */}
                  {/* Product Descriptions */}
                  <ProductDescription />
                  {/* Price and Stock */}
                  <PriceAndStock
                    isEnableProductVariable={isEnableProductVariable}
                    productVariations={productVariations}
                    variations={variations}
                    handleAddRow={this.handleAddRow}
                    handleRemoveSpecificRow={this.handleRemoveSpecificRow}
                  />
                  {/* Services */}
                  <Services />
                  {/* Package Details */}
                  <PackageDetails fieldValues={fieldValues} />
                  {/* Domestic Shipping Details */}
                  <DomesticShipping fieldValues={fieldValues} />
                  {/* International Shipping */}
                  {IS_INTERNATIONAL_SHIPPING && (
                    <InternationalShipping
                      shippingMethodList={shippingMethodList}
                      internationalShippingTrackingAvailableHandler={
                        this.internationalShippingTrackingAvailableHandler
                      }
                      fieldValues={fieldValues}
                    />
                  )}
                  {/* Shipping Details */}
                  {IS_ENABLED_ADD_PRODUCT_FORM_SHIPPING_DETAILS && (
                    <ShippingDetails fieldValues={fieldValues} />
                  )}
                  {/* Return Policies */}
                  <ReturnPolicies />
                  <Row>
                    <Col span={24} style={{ padding: "0px" }}>
                      <div className="">
                        <Button
                          type="primary"
                          block
                          htmlType="submit"
                          className="btn new-btn-clr"
                        >
                          {mode === "CREATE" ? "Add Product" : "Update Product"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </Spin>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues("AddPoduct_form")(state),
    productCategories: state.ProductManagement.productCategories,
    productImages: state.ProductManagement.productImages,
    variationsImages: state.ProductManagement.variationsImages,
    getProduct: state.ProductManagement.getProduct,
    variationTableDto: state.ProductManagement.variationTableDto,
    shippingMethodList: state.ProductManagement.shippingMethod,
    createProduct: state.ProductManagement.createProduct,
    updateProduct: state.ProductManagement.updateProduct,
    sellerDetails: state.DashboardProfile.sellerDetails,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
    dashboardProfileActions: bindActionCreators(
      dashboardProfileActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "AddPoduct_form",
    validate,
    onSubmitFail: scrollIntoFirstError,
  })(connect(mapStateToProps, mapDispatchToProps)(AddPoduct))
);
//1344

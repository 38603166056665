import React from "react";
import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal, SHOPPING_CART } from "../../../constants/Utilities";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { getSelectedProductPrice } from "../../../util/services";
import { IS_DOMESTIC_MODE, SUCCESS_TOASTER_TIMEOUT } from "../../../constants";

const initialState = {
  selectedItems: {
    selectedRows: [],
    subTotal: 0,
    shipping: 0,
    total: 0,
    ...initialStateModal,
  },
  myCart: {
    items: [],
  },
  myCartObject: {
    items: [],
  },
  checkoutItems: {
    items: [],
  },
  checkoutItemsSummery: {
    ...initialStateModal,
  },
  cartSettings: {
    displaySucess: false,
  },
  product: {
    ...initialStateModal,
  },
  relatedProductsForProduct: {
    ...initialStateModal,
  },
  makePayemt: {
    ...initialStateModal,
    needSecureAcceptance: false,
  },
  needSecureAcceptance: {
    needSecureAcceptance: false,
  },
  makeAuthorizePayemt: {
    ...initialStateModal,
  },
  checkoutDetails: {
    shippingInfo: null,
    cardInfo: null,
    billInfo: null,
  },
  shippingCost: {
    ...initialStateModal,
    selectedMethod: null,
    requestedProductId: null,
  },
  userSelections: {
    quantity: 1,
    variations: null,
  },
  updateShippingCost: {
    ...initialStateModal,
  },
  addWishlistItem: {
    ...initialStateModal,
  },
  removeWishlistItem: {
    ...initialStateModal,
  },
  relatedProducts: {
    ...initialStateModal,
  },
  homeProducts: {
    ...initialStateModal,
  },
  productFAQS: {
    ...initialStateModal,
  },
  sidebarToggle: {
    isVisibleSidebar: false,
  },
  sellerSidebarToggle: {
    isVisibleSidebar: false,
  },
  settingsUpdate: {
    width: 1920,
  },
  paymentMethod: {
    method: IS_DOMESTIC_MODE ? "cash_on_delivery" : "credit_card",
  },
  checkOrderQuantity: {
    ...initialStateModal,
  },
  selectedDomesticShippingLocation: {
    data: null,
  },
  allDistricts: {
    ...initialStateModal,
  },
  areaByDistrict: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    //---------------------CLEAR_SELECT_ITEM-------------------------------//
    [types.CLEAR_SELECT_ITEM]: (state, { payload }) => ({
      ...state,
      selectedItems: {
        selectedRows: [],
        subTotal: 0,
        shipping: 0,
        total: 0,
        ...initialStateModal,
      },
    }),

    //---------------------CLEAR_PRODUCT_INFO-------------------------------//
    [types.CLEAR_PRODUCT_INFO]: (state, { payload }) => ({
      ...state,
      product: {
        ...initialStateModal,
      },
      relatedProductsForProduct: {
        ...initialStateModal,
      },
    }),
    //---------------------PAYMENT METHOD HANDLE-------------------------------//
    [types.PAYMENT_METHOD_HANDLE]: (state, { payload }) => ({
      ...state,
      paymentMethod: {
        method: payload.method,
      },
    }),
    //---------------------SELECTED_DOMESTIC_SHIPPING_LOCATION-------------------------------//
    [types.SELECTED_DOMESTIC_SHIPPING_LOCATION]: (state, { payload }) => ({
      ...state,
      selectedDomesticShippingLocation: {
        data: payload,
      },
      shippingCost: {
        ...state.shippingCost,
        pending: Object.keys(payload).length === 0 ? false : true,
      },
    }),
    //---------------------SIDEBAR TOGGLE -------------------------------//
    [types.SIDEBAR_TOGGLE]: (state, { payload }) => ({
      ...state,
      sidebarToggle: {
        isVisibleSidebar: !state.sidebarToggle.isVisibleSidebar,
      },
    }),
    //---------------------SELLER SIDEBAR TOGGLE -------------------------------//
    [types.SELLER_SIDEBAR_TOGGLE]: (state, { payload }) => ({
      ...state,
      sellerSidebarToggle: {
        isVisibleSidebar: !state.sellerSidebarToggle.isVisibleSidebar,
      },
    }),
    //---------------------SETTINGS UPDATE -------------------------------//
    [types.SETTINGS_UPDATE]: (state, { payload }) => ({
      ...state,
      settingsUpdate: {
        width: payload.width,
      },
    }),
    //---------------------RESET CHECKOUT ITEM -------------------------------//
    [types.RESET_CHECKOUT_ITEMS]: (state, { payload }) => ({
      ...state,
      checkoutItems: {
        items: [],
      },
    }),
    //---------------------GET PRODUCT -------------------------------//
    [types.GET_PRODUCT]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),
    [types.GET_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_PRODUCT_FAILED]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------

    //---------------------GET PRODUCT -------------------------------//
    [types.GET_RELATED_PRODUCTS_FOR_PRODUCT]: (state, { payload }) => ({
      ...state,
      relatedProductsForProduct: {
        ...state.relatedProductsForProduct,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),
    [types.GET_RELATED_PRODUCTS_FOR_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      relatedProductsForProduct: {
        ...state.relatedProductsForProduct,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_RELATED_PRODUCTS_FOR_PRODUCT_FAILED]: (state, { payload }) => ({
      ...state,
      relatedProductsForProduct: {
        ...state.relatedProductsForProduct,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //---------------------GET HOME PRODUCTS -------------------------------//

    [types.GET_HOME_PRODUCTS]: (state, { payload }) => ({
      ...state,
      homeProducts: {
        ...state.homeProducts,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_HOME_PRODUCTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      homeProducts: {
        ...state.homeProducts,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_HOME_PRODUCTS_FAILED]: (state, { payload }) => ({
      ...state,
      homeProducts: {
        ...state.homeProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    //---------------------MAKE PAYMENT -------------------------------//
    [types.MAKE_PAYMENT]: (state, { payload }) => ({
      ...state,
      makePayemt: {
        ...state.makePayemt,
        loading: true,
        pending: true,
      },
    }),
    [types.MAKE_PAYMENT_SUCCESS]: (state, { payload }) => {
      // debugger;
      var myCartString = localStorage.getItem("myCart");
      var myCartObj = JSON.parse(myCartString);
      var myCheckoutString = localStorage.getItem("checkoutItems");
      var myCheckoutObj = JSON.parse(myCheckoutString);

      // OS-550 NEED TO VALIDATE FUNCTION
      try {
        const results = myCartObj.filter(
          ({ id: id1 }) => !myCheckoutObj.some(({ id: id2 }) => id1 === id2)
        );

        var updatedMyCartString = JSON.stringify(results);
        // setter
        localStorage.setItem("myCart", updatedMyCartString);
      } catch (error) {
        
      }

      localStorage.removeItem("checkoutItems");

      return {
        ...state,
        makePayemt: {
          ...state.makePayemt,
          loading: false,
          pending: false,
          data: payload?.data,
          needSecureAcceptance: payload?.needSecureAcceptance,
        },
      };
    },

    [types.MAKE_PAYMENT_FAILED]: (state, { payload }) => ({
      ...state,
      makePayemt: {
        ...state.makePayemt,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------

    //--------------------- MAKE_AUTHORIZE_PAYMENT -------------------------------//
    [types.MAKE_AUTHORIZE_PAYMENT]: (state, { payload }) => ({
      ...state,
      makeAuthorizePayemt: {
        ...state.makeAuthorizePayemt,
        loading: true,
        pending: true,
      },
      makePayemt: {
        ...state.makePayemt,
        loading: true,
        pending: true,
      },
    }),
    [types.MAKE_AUTHORIZE_PAYMENT_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        makeAuthorizePayemt: {
          ...state.makeAuthorizePayemt,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.MAKE_AUTHORIZE_PAYMENT_FAILED]: (state, { payload }) => ({
      ...state,
      makeAuthorizePayemt: {
        ...state.makeAuthorizePayemt,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //--------------------- SECURE_ACCEPTANCE_HANDLER -------------------------------//
    [types.SECURE_ACCEPTANCE_HANDLER]: (state, { payload }) => {
      // debugger;
      return {
        ...state,
        needSecureAcceptance: {
          needSecureAcceptance: payload?.needSecureAcceptance,
        },
      };
    },
    //---------------------ADD USER SELECTIONS -------------------------------//
    [types.ADD_USER_SELECTIONS]: (state, { payload }) => {
      const { key, value } = payload;
      return {
        ...state,
        userSelections: {
          ...state.userSelections,
          [key]: value,
        },
        product: {
          ...state.product,
          data: {
            ...state.product.data,
            data: {
              ...state.product.data.data,
              userSelections: {
                ...state.product.data.data.userSelections,
                [key]: value,
              },
            },
          },
        },
      };
    },
    //----------------------------------------------------------//

    //---------------------CLEAR_USER_SELECTIONS -------------------------------//
    [types.CLEAR_USER_SELECTIONS]: (state, { payload }) => {
      return {
        ...state,
        userSelections: {
          quantity: 1,
          variations: null,
        },
      };
    },
    //---------------------ORDER SUMMERY -------------------------------//
    [types.SELECT_ITEM]: (state, { payload }) => {
      var subTotal = payload.selectedRows.reduce(function (subTotal, item) {
        var selectedProductVariation = getSelectedProductPrice(
          item.variations,
          item.userSelections.variations,
          item
        );
        // return the sum with previous value
        return (
          subTotal +
          parseFloat(selectedProductVariation.price) *
            item.userSelections.quantity
        );
        // set initial value as 0
      }, 0);
      var shipping = payload.selectedRows.reduce(function (shipping, item) {
        // return the sum with previous value
        return (
          shipping +
          parseFloat(item.selectedShippingMethod.rate) +
          parseFloat(item.selectedShippingMethod.rate_additional_item) *
            (item.userSelections.quantity - 1)
        );
        // set initial value as 0
      }, 0);

      var total = subTotal + shipping;
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          selectedRows: payload.selectedRows,
          subTotal: subTotal.toFixed(2),
          shipping: shipping.toFixed(2),
          total: total.toFixed(2),
        },
      };
    },
    //----------------------------------------------------------//
    //-------------------------CHECKOUT ITEMS---------------------------------//
    [types.CHECKOUT_ITEMS]: (state, { payload }) => {
      if (!Array.isArray(payload.item)) {
        state.checkoutItems.items.push(payload.item);
        var checkoutItemsObj = state.checkoutItems.items;
        var checkoutItemsString = JSON.stringify(checkoutItemsObj);
        // setter
        localStorage.setItem("checkoutItems", checkoutItemsString);

        // const subTotal = payload.item.reduce(function (subTotal, item) {
        //   let product = getSelectedProductPrice(
        //     item.variations,
        //     item.userSelections.variations,
        //     item
        //   );
        //   // return the sum with previous value
        //   return (
        //     subTotal + parseFloat(product.price) * item.userSelections.quantity
        //   );
        //   // set initial value as 0
        // }, 0);
        // const shipping = payload.item.reduce(function (shipping, item) {
        //   // return the sum with previous value
        //   // return shipping + parseFloat(item.selectedShippingMethod.rate);
        //   return (
        //     shipping +
        //     (parseFloat(item.selectedShippingMethod.rate) +
        //       parseFloat(item.selectedShippingMethod.rate_additional_item) *
        //         (item.userSelections.quantity - 1))
        //   );
        //   // set initial value as 0
        // }, 0);

        // var total = subTotal + shipping;

        return {
          ...state,
          checkoutItems: {
            ...state.checkoutItems,
            // subTotal: subTotal.toFixed(2),
            // shipping: shipping.toFixed(2),
            // total: total.toFixed(2),
          },
        };
      } else {
        var checkoutItems_String = JSON.stringify(payload.item);

        // setter
        localStorage.setItem("checkoutItems", checkoutItems_String);

        // const subTotal = payload.item.reduce(function (subTotal, item) {
        //   let product = getSelectedProductPrice(
        //     item.variations,
        //     item.userSelections.variations,
        //     item
        //   );
        //   // return the sum with previous value
        //   return (
        //     subTotal + parseFloat(product.price) * item.userSelections.quantity
        //   );
        //   // set initial value as 0
        // }, 0);
        // const shipping = payload.item.reduce(function (shipping, item) {
        //   // return the sum with previous value
        //   return (
        //     shipping +
        //     (parseFloat(item.selectedShippingMethod.rate) +
        //       parseFloat(item.selectedShippingMethod.rate_additional_item) *
        //         (item.userSelections.quantity - 1))
        //   );
        //   // set initial value as 0
        // }, 0);

        // var total = subTotal + shipping;

        return {
          ...state,
          checkoutItems: {
            ...state.checkoutItems,
            items: [...payload.item],
            // total: subTotal + shipping,
            // subTotal: subTotal.toFixed(2),
            // shipping: shipping.toFixed(2),
            // total: total.toFixed(2),
          },
        };
      }
    },
    //----------------------------------------------------------//
    //-------------------------INITIALIZE REDUX CHECKOUT CART---------------------------------//
    [types.INITIALIZE_REDUX_CHECKOUT_CART]: (state, { payload }) => {
      // debugger
      // var subTotal = payload.checkoutItemsObj.reduce(function (subTotal, item) {
      //   let product = getSelectedProductPrice(
      //     item.variations,
      //     item.userSelections.variations,
      //     item
      //   );
      //   // return the sum with previous value
      //   return (
      //     subTotal + parseFloat(product.price) * item.userSelections.quantity
      //   );
      //   // set initial value as 0
      // }, 0);
      // var shipping = payload.checkoutItemsObj.reduce(function (shipping, item) {
      //   // return the sum with previous value
      //   // return (
      //   //   shipping +
      //   //   parseFloat(item.selectedShippingMethod.rate) *
      //   //     item.userSelections.quantity
      //   // );
      //   return (
      //     shipping +
      //     (parseFloat(item.selectedShippingMethod.rate) +
      //       parseFloat(item.selectedShippingMethod.rate_additional_item) *
      //         (item.userSelections.quantity - 1))
      //   );
      //   // set initial value as 0
      // }, 0);

      // var total = subTotal + shipping;
      return {
        ...state,
        checkoutItems: {
          ...state.checkoutItems,
          items: [...payload.checkoutItemsObj],
          // subTotal: subTotal.toFixed(2),
          // shipping: shipping.toFixed(2),
          // total: total.toFixed(2),
        },
      };
    },
    //----------------------------------------------------------//

    [types.CHECKOUT_CALCULATION]: (state, { payload }) => ({
      ...state,
      checkoutItemsSummery: {
        ...state.checkoutItemsSummery,
        loading: true,
        pending: true,
      },
    }),

    [types.CHECKOUT_CALCULATION_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      // debugger;

      return {
        ...state,
        checkoutItemsSummery: {
          ...state.checkoutItemsSummery,
          loading: false,
          pending: false,
          data: data,
        },
      };
    },

    [types.CHECKOUT_CALCULATION_FAILED]: (state, { payload }) => ({
      ...state,
      checkoutItemsSummery: {
        ...state.checkoutItemsSummery,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------Initialize Location Picker Value---------------------------------//
    [types.INITIALIZE_LOCATION_PICKER_VALUE]: (state, { payload }) => {
      // state.myCart.items.push(payload.myCartObj);
      return {
        ...state,
        selectedDomesticShippingLocation: {
          data: payload?.selectedLocation,
        },
        // cartSettings: {
        //   ...state.cartSettings,
        //   displaySucess: payload.displaySucess,
        // },
      };
    },
    //----------------------------------------------------------//

    //-------------------------INITIALIZE REDUX CART---------------------------------//
    [types.INITIALIZE_REDUX_CART]: (state, { payload }) => {
      state.myCart.items.push(payload.myCartObj);
      return {
        ...state,
        myCart: {
          ...state.myCart,
          items: [
            ...(payload.myCartObj || []).map((item, idx) => {
              return {
                ...item,
                key: `${item.id}-${idx}`,
              };
            }),
          ],
        },
        cartSettings: {
          ...state.cartSettings,
          displaySucess: payload.displaySucess,
        },
      };
    },
    //----------------------------------------------------------//
    //-------------------------ADD MY CART---------------------------------//
    [types.ADD_CART_ITEM]: (state, { payload }) => {
      // debugger
      // getter
      var prevMyCartString = localStorage.getItem("myCart");
      if (prevMyCartString) {
        var myCartObj = JSON.parse(prevMyCartString);
        // myCartObj.push(payload.item);
        myCartObj = [payload.item].concat(myCartObj);
        var myCartString = JSON.stringify(myCartObj);
        // setter
        localStorage.setItem("myCart", myCartString);
      } else {
        var myCart_Obj = [];
        // myCart_Obj.push(payload.item);
        myCart_Obj = [payload.item].concat(myCart_Obj);
        var myCart_String = JSON.stringify(myCart_Obj);
        // setter
        localStorage.setItem("myCart", myCart_String);
      }

      // state.myCart.items.push(payload.item);
      // var updatedMyCartItems = [payload.item].concat(state.myCart.items)
      var updatedMyCartItems = [payload.item, ...state.myCart.items];

      return {
        ...state,
        myCart: {
          ...state.myCart,
          items: updatedMyCartItems,
        },
        cartSettings: {
          ...state.cartSettings,
          displaySucess: payload.displaySucess,
        },
      };
    },
    //----------------------------------------------------------//
    //-------------------------UPDATE EXIST ITEM IN MY CART---------------------------------//
    [types.UPDATE_EXIST_CART_ITEM]: (state, { payload }) => {
      // set local storage
      var myCart_String = JSON.stringify(payload.myCart);
      localStorage.setItem("myCart", myCart_String);
      return {
        ...state,
        myCart: {
          items: payload.myCart,
        },
        cartSettings: {
          ...state.cartSettings,
          displaySucess: payload.displaySucess,
        },
      };
    },
    //----------------------------------------------------------//
    //-------------------------REMOVE MY CART---------------------------------//
    [types.REMOVE_CART_ITEM]: (state, { payload }) => {
      // getter
      var myCartString = localStorage.getItem("myCart");
      var myCartObj = JSON.parse(myCartString);

      const localStorageIndex = myCartObj.findIndex(
        (obj) => obj.id === payload.item1.id
      );

      myCartObj.splice(localStorageIndex, 1);

      myCartString = JSON.stringify(myCartObj);

      // // setter
      localStorage.setItem("myCart", myCartString);
      // _.remove(state.myCart.items, {
      //   objectID: payload.item.objectID,
      // });
      state.myCart.items.splice(localStorageIndex, 1);
      // _.remove(state.myCart.items, {
      //   id: payload.item.id,
      // });

      let selectedProducts = payload.selectedProducts1;

      const index = selectedProducts.findIndex(
        (product) => product.id === payload.item1.id
      );

      if (index !== undefined) {
        selectedProducts.splice(index, 1);

        var subTotal = selectedProducts.reduce(function (subTotal, item) {
          var selectedProductVariation = getSelectedProductPrice(
            item.variations,
            item.userSelections.variations,
            item
          );
          // return the sum with previous value
          return (
            subTotal +
            parseFloat(selectedProductVariation.price) *
              item.userSelections.quantity
          );
          // set initial value as 0
        }, 0);

        var shipping = selectedProducts.reduce(function (shipping, item) {
          // return the sum with previous value
          // return (
          //   shipping +
          //   parseFloat(item.selectedShippingMethod.rate) *
          //     item.userSelections.quantity
          // );
          return (
            shipping +
            (parseFloat(item.selectedShippingMethod.rate) +
              parseFloat(item.selectedShippingMethod.rate_additional_item) *
                (item.userSelections.quantity - 1))
          );
          // set initial value as 0
        }, 0);

        var total = subTotal + shipping;

        return {
          ...state,
          selectedItems: {
            ...state.selectedItems,
            selectedRows: selectedProducts,
            subTotal: subTotal.toFixed(2),
            shipping: shipping.toFixed(2),
            total: total.toFixed(2),
          },
          myCart: {
            ...state.myCart,
          },
        };
      } else {
        return {
          ...state,
          myCart: {
            ...state.myCart,
          },
        };
      }

      // return {
      //   ...state,

      // };
    },
    //----------------------------------------------------------//
    //-------------------------CART SETTINGS HANDLER---------------------------------//
    [types.CART_SETTINGS_HANDLER]: (state, { payload }) => {
      return {
        ...state,
        cartSettings: {
          ...state.cartSettings,
          displaySucess: payload.displaySucess,
        },
      };
    },
    //----------------------------------------------------------//
    //-------------------------SHIPPING COST---------------------------------//
    [types.GET_SHIPPING_COST]: (state, { payload }) => ({
      ...state,
      shippingCost: {
        ...state.shippingCost,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),
    [types.GET_SHIPPING_COST_SUCCESS]: (state, { payload }) => {
      if (payload.requestObj.isCart) {
        // var updatedIndex = state.myCart.items.findIndex(
        //   (item) => item.id === payload.requestObj.id
        // );
        return {
          ...state,
          shippingCost: {
            ...state.shippingCost,
            loading: false,
            pending: false,
            hasError: false,
            data: payload.data,
            selectedMethod: payload.data.data[0],
            requestedProductId: payload.requestObj.id,
          },
          // myCart:{
          //   ...state.myCart,
          //   items:[
          //     ...state.myCart.items,
          //     items[updatedIndex].selectedShippingMethod:
          //   ]
          // }
        };
      } else {
        return {
          ...state,
          shippingCost: {
            ...state.shippingCost,
            loading: false,
            pending: false,
            hasError: false,
            data: payload.data,
            selectedMethod: payload.data.data.rates[0],
            requestedProductId: payload.requestObj.id,
          },
          product: {
            ...state.product,
            data: {
              ...state.product.data,
              data: {
                ...state.product.data.data,
                selectedShippingMethod: payload.data.data.rates[0],
              },
            },
          },
        };
      }
    },

    [types.GET_SHIPPING_COST_FAILED]: (state, { payload }) => ({
      ...state,
      shippingCost: {
        ...state.shippingCost,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //-------------------------SHIPPING SELECTED METHOD HANDLER---------------------------------//

    [types.SELECT_SHIPPING_METHOD]: (state, { payload }) => {
      if (payload.isCart) {
        var updatedIndex = state.myCart.items.findIndex(
          (item) => item.id === payload.product_id
        );
        var tempObj = null;
        if (updatedIndex !== -1) {
          tempObj = state.myCart.items[updatedIndex];
        }

        var updatedObj = {
          ...tempObj,
          selectedShippingMethod: payload.selectedMethod[0],
        };

        // Replace the item by index.
        state.myCart.items.splice(updatedIndex, 1, updatedObj);

        var updatedMyCartString = JSON.stringify(state.myCart.items);
        // setter
        localStorage.setItem("myCart", updatedMyCartString);

        return {
          ...state,
          shippingCost: {
            ...state.shippingCost,
            selectedMethod: payload.selectedMethod[0],
          },
          myCart: {
            ...state.myCart,
            status: "UPDATED",
          },
        };
      } else if (payload.isCheckout) {
        // debugger;
        var updated_Index = state.checkoutItems.items.findIndex(
          (item) => item.id === payload.product_id
        );
        var temp_Obj = null;
        if (updated_Index !== -1) {
          temp_Obj = state.checkoutItems.items[updated_Index];
        }

        var updated_Obj = {
          ...temp_Obj,
          selectedShippingMethod: payload.selectedMethod[0],
        };

        // Replace the item by index.
        state.checkoutItems.items.splice(updated_Index, 1, updated_Obj);

        // var subTotal = state.checkoutItems.items.reduce(function (
        //   subTotal,
        //   item
        // ) {
        //   // return the sum with previous value
        //   // return subTotal + parseFloat(item.price);
        //   return (
        //     subTotal + parseFloat(product.price) * item.userSelections.quantity
        //   );
        //   // set initial value as 0
        // },
        // 0);

        var subTotal = payload.checkoutItemsObj.reduce(function (
          subTotal,
          item
        ) {
          let product = getSelectedProductPrice(
            item.variations,
            item.userSelections.variations,
            item
          );
          // return the sum with previous value
          return (
            subTotal + parseFloat(product.price) * item.userSelections.quantity
          );
          // set initial value as 0
        },
        0);

        var shipping = state.checkoutItems.items.reduce(function (
          shipping,
          item
        ) {
          // return the sum with previous value
          // return shipping + parseFloat(item.selectedShippingMethod.rate);
          return (
            shipping +
            (parseFloat(item.selectedShippingMethod.rate) +
              parseFloat(item.selectedShippingMethod.rate_additional_item) *
                (item.userSelections.quantity - 1))
          );
          // set initial value as 0
        },
        0);

        var updatedCheckoutItemsString = JSON.stringify(
          state.checkoutItems.items
        );
        // setter
        localStorage.setItem("checkoutItems", updatedCheckoutItemsString);
        return {
          ...state,
          shippingCost: {
            ...state.shippingCost,
            selectedMethod: payload.selectedMethod[0],
          },
          checkoutItems: {
            ...state.checkoutItems,
            status: "UPDATED",
            subTotal: subTotal.toFixed(2),
            shipping: shipping.toFixed(2),
            total: subTotal + shipping,
          },
        };
      } else {
        return {
          ...state,
          shippingCost: {
            ...state.shippingCost,
            selectedMethod: payload.selectedMethod[0],
          },
          product: {
            ...state.product,
            data: {
              ...state.product.data,
              data: {
                ...state.product.data.data,
                selectedShippingMethod: payload.selectedMethod[0],
              },
            },
          },
        };
      }
    },
    //-------------------------------------------------------

    //-------------------------PRODUCT USER SELECTIONS HANDLER---------------------------------//

    [types.PRODUCT_USER_SELECTIONS_HANDLER]: (state, { payload }) => {
      // debugger;
      const { key, value, updatedIndex } = payload;
      if (payload.isCart) {
        //get object which shoud be updated
        var tempObj = null;
        if (updatedIndex !== -1) {
          tempObj = state.myCart.items[updatedIndex];
        }

        var selectedProductVariations = getSelectedProductPrice(
          tempObj.variations,
          tempObj.userSelections.variations,
          tempObj
        );

        if (value <= selectedProductVariations.qty) {
          tempObj["product_unvailable"] = false;
        }

        //Updated object
        var updatedObj = {
          ...tempObj,
          userSelections: {
            ...tempObj.userSelections,
            [key]: value,
          },
        };

        state.myCart.items.splice(updatedIndex, 1, updatedObj);
        // object -> string
        // var updatedMyCartString = JSON.stringify(state.myCart.items);

        // setter to local storage
        // localStorage.setItem("myCart", updatedMyCartString);
        return {
          ...state,
          userSelections: {
            ...state.userSelections,
            [key]: value,
          },
          myCart: {
            ...state.myCart,
            status: "UPDATED",
          },
        };
      } else if (payload.isCheckout) {
        // debugger;
        //create temp object which should be updated
        var temp_Obj = null;
        if (updatedIndex !== -1) {
          temp_Obj = state.checkoutItems.items[updatedIndex];
        }
        // updated object
        var updated_Obj = {
          ...temp_Obj,
          userSelections: {
            ...temp_Obj.userSelections,
            [key]: value,
          },
        };

        // Replace the updated object by index.
        state.checkoutItems.items.splice(updatedIndex, 1, updated_Obj);

        // var subTotal = state.checkoutItems.items.reduce(function (
        //   subTotal,
        //   item
        // ) {
        //   var selectedProductVariation = getSelectedProductPrice(
        //     item.variations,
        //     item.userSelections.variations,
        //     item
        //   );

        //   // return the sum with previous value
        //   return (
        //     subTotal +
        //     parseFloat(selectedProductVariation.price) *
        //       item.userSelections.quantity
        //   );
        //   // set initial value as 0
        // },
        // 0);

        // var subTotal = selectedProducts.reduce(function (subTotal, item) {
        //   var selectedProductVariation = getSelectedProductPrice(
        //     item.variations,
        //     item.userSelections.variations,
        //     item
        //   );
        //   // return the sum with previous value
        //   return (
        //     subTotal +
        //     parseFloat(selectedProductVariation.price) *
        //       item.userSelections.quantity
        //   );
        //   // set initial value as 0
        // }, 0);

        // var shipping = state.checkoutItems.items.reduce(function (
        //   shipping,
        //   item
        // ) {
        //   // return the sum with previous value
        //   // return (
        //   //   shipping +
        //   //   parseFloat(item.selectedShippingMethod.rate) *
        //   //     item.userSelections.quantity
        //   // );
        //   return (
        //     shipping +
        //     (parseFloat(item.selectedShippingMethod.rate) +
        //       parseFloat(item.selectedShippingMethod.rate_additional_item) *
        //         (item.userSelections.quantity - 1))
        //   );
        //   // set initial value as 0
        // },
        // 0);

        // var total = subTotal + shipping;
        // updated object -> string
        var updatedCheckoutItemsString = JSON.stringify(
          state.checkoutItems.items
        );
        // set the local storage
        localStorage.setItem("checkoutItems", updatedCheckoutItemsString);
        return {
          ...state,
          userSelections: {
            ...state.userSelections,
            [key]: value,
          },
          checkoutItems: {
            ...state.checkoutItems,
            status: "UPDATED",
          },
        };
      } else {
        // debugger;
        return {
          ...state,
          userSelections: {
            ...state.userSelections,
            [key]: value,
          },
          product: {
            ...state.product,
            data: {
              ...state.product.data,
              data: {
                ...state.product.data.data,
                userSelections: {
                  ...state.product.data.data.userSelections,
                  [key]: value,
                },
              },
            },
          },
        };
      }
    },
    //-------------------------------------------------------
    //-------------------------PRODUCT USER SELECTIONS HANDLER---------------------------------//

    [types.SELECT_VARIATIONS]: (state, { payload }) => {
      // debugger;
      const { key, value } = payload;
      return {
        ...state,
        userSelections: {
          ...state.userSelections,
          variations: {
            ...state.product.data.data.userSelections.variations,
            [key]: value,
          },
        },
        product: {
          ...state.product,
          data: {
            ...state.product.data,
            data: {
              ...state.product.data.data,
              userSelections: {
                ...state.product.data.data.userSelections,
                variations: {
                  ...state.product.data.data.userSelections.variations,
                  [key]: value,
                },
              },
            },
          },
        },
      };
    },
    //-------------------------------------------------------

    //-------------------------PRODUCT USER SELECTIONS HANDLER---------------------------------//

    [types.DESELECT_VARIATIONS]: (state, { payload }) => {
      const { key } = payload;
      var updatedVariation = _.clone(
        state.product.data.data.userSelections.variations
      );
      delete updatedVariation[key];

      return {
        ...state,
        userSelections: {
          ...state.userSelections,
          variations: {
            ...updatedVariation,
          },
        },
        product: {
          ...state.product,
          data: {
            ...state.product.data,
            data: {
              ...state.product.data.data,
              userSelections: {
                ...state.product.data.data.userSelections,
                variations: {
                  ...updatedVariation,
                },
              },
            },
          },
        },
      };
    },
    //-------------------------------------------------------

    //-------------------------SHIPPING INFO---------------------------------//
    [types.CONFIRM_SHIPPING_INFO]: (state, { payload }) => {
      return {
        ...state,
        checkoutDetails: {
          ...state.checkoutDetails,
          shippingInfo: payload.shippingInfoDto,
          billInfo: payload.shippingInfoDto,
        },
        checkoutItems: {
          ...state.checkoutItems,
          status: "PENDING",
        },
        updateShippingCost: {
          ...state.updateShippingCost,
          pending: true,
        },
      };
    },
    //-------------------------CARD INFO---------------------------------//
    [types.CONFIRM_CARD_INFO]: (state, { payload }) => {
      // NotificationManager.info(
      //   <IntlMessages id="notification.checkout.paymentMethod.successMessage" />,
      //   <IntlMessages id="notification.checkout.paymentMethod.title" />,
      //    SUCCESS_TOASTER_TIMEOUT
      // );
      return {
        ...state,
        checkoutDetails: {
          ...state.checkoutDetails,
          cardInfo: payload.cardInfoDto,
        },
      };
    },

    //---------------------UPDATE SHIPPING COST -------------------------------//
    [types.UPDATE_SHIPPING_COST]: (state, { payload }) => {
      return {
        ...state,
        updateShippingCost: {
          ...state.updateShippingCost,
          loading: true,
          pending: true,
        },
      };
    },
    [types.UPDATE_SHIPPING_COST_SUCCESS]: (state, { payload }) => {
      // debugger;
      let updated = false;
      let updatedCheckoutItems = state.checkoutItems;

      //map the checkout items
      updatedCheckoutItems.items.forEach((item, i) => {
        payload.forEach((responseItem, j) => {
          //match the correct item
          if (item.id === responseItem.id) {
            // var defaultMethod = responseItem.data && responseItem.data[0];
            var defaultMethod = responseItem.data && responseItem.data.rates;

            //map the all shipping methods
            responseItem.data.rates.forEach((shippingMethod, k) => {
              //match the selected item in previous
              if (
                shippingMethod.service ===
                state.checkoutItems.items[i].selectedShippingMethod.service
              ) {
                state.checkoutItems.items[i].selectedShippingMethod =
                  shippingMethod;
                updated = true;
              }
              //handle if previous shipping method not in there
              // else {
              //   state.checkoutItems.items[
              //     i
              //   ].selectedShippingMethod = defaultMethod;
              // }
              // return null;

              // need to verify ************
              // return null;
            });
            if (!updated) {
              state.checkoutItems.items[i].selectedShippingMethod =
                defaultMethod[0];
            }
          }
          // return null;
        });
        // return null;
      });

      NotificationManager.success(
        <IntlMessages id="notification.checkout.updateShippingInfo.shippingCostUpdated.successMessage" />,
        <IntlMessages id="notification.checkout.updateShippingInfo.shippingCostUpdated.title" />,
        SUCCESS_TOASTER_TIMEOUT
      );
      // //checkout Item convert to string
      // var checkoutItemsString = JSON.stringify(state.checkoutItems);
      // console.log("********* ******** 🚀 ~ file: reducers.js ~ line 1109 ~ checkoutItemsString", checkoutItemsString)
      // // set updated object to local storage
      // localStorage.setItem("checkoutItems", checkoutItemsString);
      return {
        ...state,
        updateShippingCost: {
          ...state.updateShippingCost,
          loading: false,
          pending: false,
          data: payload,
        },
        checkoutItems: {
          ...state.checkoutItems,
        },
      };
    },

    [types.UPDATE_SHIPPING_COST_FAILED]: (state, { payload }) => ({
      ...state,
      updateShippingCost: {
        ...state.updateShippingCost,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //---------------------ADD WISH LIST ITEM -------------------------------//
    [types.ADD_WISHLIST_ITEM]: (state, { payload }) => ({
      ...state,
      addWishlistItem: {
        ...state.addWishlistItem,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_WISHLIST_ITEM_SUCCESS]: (state, { payload }) => {
      const { wishlistDto, isHome, isSingleProduct } = payload;
      const { product_id } = wishlistDto;
      // debugger;
      if (isHome) {
        const updatedIndex = state.homeProducts.data.findIndex(
          (item) => item.id === product_id
        );
        var updatedObj = {
          ...state.homeProducts.data[updatedIndex],
          is_wishlisted: !state.homeProducts.data[updatedIndex].is_wishlisted,
        };

        var source = state.homeProducts.data;
        source[updatedIndex] = updatedObj;

        return {
          ...state,
          addWishlistItem: {
            ...state.addWishlistItem,
            loading: false,
            pending: false,
            data: payload,
          },
          homeProducts: {
            ...state.homeProducts,
            data: source,
          },
        };
      } else if (isSingleProduct) {
        // debugger;
        var prev_is_wishlisted =
          state.product.data &&
          state.product.data.data &&
          state.product.data.data.is_wishlisted;
        return {
          ...state,
          addWishlistItem: {
            ...state.addWishlistItem,
            loading: false,
            pending: false,
            data: payload,
          },
          product: {
            ...state.product,
            data: {
              ...state.product.data,
              data: {
                ...state.product.data.data,
                is_wishlisted: !prev_is_wishlisted,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          addWishlistItem: {
            ...state.addWishlistItem,
            loading: false,
            pending: false,
            data: payload,
          },
        };
      }
    },

    [types.ADD_WISHLIST_ITEM_FAILED]: (state, { payload }) => ({
      ...state,
      addWishlistItem: {
        ...state.addWishlistItem,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //---------------------REMOVE WISH LIST ITEM -------------------------------//
    [types.REMOVE_WISHLIST_ITEM]: (state, { payload }) => ({
      ...state,
      removeWishlistItem: {
        ...state.removeWishlistItem,
        loading: true,
        pending: true,
      },
    }),
    [types.REMOVE_WISHLIST_ITEM_SUCCESS]: (state, { payload }) => {
      // window.location.reload();

      return {
        ...state,
        removeWishlistItem: {
          ...state.removeWishlistItem,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.REMOVE_WISHLIST_ITEM_FAILED]: (state, { payload }) => ({
      ...state,
      removeWishlistItem: {
        ...state.removeWishlistItem,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //---------------------ADD PRODUCT FAQS-------------------------------//
    [types.ADD_PRODUCT_FAQS]: (state, { payload }) => ({
      ...state,
      productFAQS: {
        ...state.productFAQS,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),
    [types.ADD_PRODUCT_FAQS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        productFAQS: {
          ...state.productFAQS,
          loading: false,
          pending: false,
          hasError: false,
          data: payload,
        },
      };
    },

    [types.ADD_PRODUCT_FAQS_FAIL]: (state, { payload }) => ({
      ...state,
      productFAQS: {
        ...state.productFAQS,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //---------------------RELATED PRODUCTS -------------------------------//
    [types.GET_RELATED_PRODUCTS]: (state, { payload }) => ({
      ...state,
      relatedProducts: {
        ...state.relatedProducts,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_RELATED_PRODUCTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      relatedProducts: {
        ...state.relatedProducts,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_RELATED_PRODUCTS_FAILED]: (state, { payload }) => ({
      ...state,
      relatedProducts: {
        ...state.relatedProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------New Reducer For producuct quantity change in cart------------------------------------------
    [types.PRODUCT_QUANTITY_CHANGE_IN_CART]: (state, { payload }) => {
      const { cartType } = payload;

      if (cartType === SHOPPING_CART) {
        return {
          ...state,
          selectedItems: {
            ...state.selectedItems,
            loading: true,
            pending: true,
          },
        };
      } else {
        return {
          ...state,
          checkoutItemsSummery: {
            ...state.checkoutItemsSummery,
            loading: true,
            pending: true,
          },
        };
      }
    },
    [types.PRODUCT_QUANTITY_CHANGE_IN_CART_SUCCESS]: (state, { payload }) => {
      const { data, cartType } = payload;

      // if cart is shopping cart
      if (cartType === SHOPPING_CART) {
        return {
          ...state,
          selectedItems: {
            ...state.selectedItems,
            loading: false,
            pending: false,
            // selectedRows: payload.selectedRows,
            subTotal: data?.data?.total_product_cost,
            shipping: data?.data?.shipping_cost,
            total: data?.data?.total_cost,
          },
        };
      } else {
        /* if checkout cart */
        return {
          ...state,
          checkoutItemsSummery: {
            ...state.checkoutItemsSummery,
            loading: false,
            pending: false,
            data: data.data,
          },
        };
      }
    },

    [types.PRODUCT_QUANTITY_CHANGE_IN_CART_FAILED]: (state, { payload }) => {
      const { message, cartType } = payload;

      // if cart is shopping cart
      if (cartType === SHOPPING_CART) {
        return {
          ...state,
          selectedItems: {
            ...state.selectedItems,
            loading: false,
            pending: false,
            hasError: true,
            error: { message },
          },
        };
      } else {
        /* if checkout cart */
        return {
          ...state,
          checkoutItemsSummery: {
            ...state.checkoutItemsSummery,
            loading: false,
            pending: false,
            hasError: true,
            error: { message },
          },
        };
      }
    },

    [types.PRODUCTS_SELECT_IN_CART]: (state, { payload }) => ({
      ...state,
      selectedItems: {
        ...state.selectedItems,
        loading: true,
        pending: true,
      },
    }),
    [types.PRODUCTS_SELECT_IN_CART_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          selectedRows: payload.data.selectedRows,
          subTotal: payload.data.total_product_cost,
          shipping: payload.data.shipping_cost,
          total: payload.data.total_cost,
          // pending: false,
          // data: payload,
          loading: false,
          pending: false,
        },
      };
    },

    [types.PRODUCTS_SELECT_IN_CART_FAILED]: (state, { payload }) => ({
      ...state,
      selectedItems: {
        ...state.selectedItems,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    [types.REMOVE_PRODUCT_FROM_CART]: (state, { payload }) => ({
      ...state,
      selectedItems: {
        ...state.selectedItems,
        loading: true,
        pending: true,
      },
    }),
    [types.REMOVE_PRODUCT_FROM_CART_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          selectedRows: data?.selectedRows,
          loading: false,
          pending: false,
          subTotal: data?.total_product_cost,
          shipping: data?.shipping_cost,
          total: data?.total_cost,
          // data: payload,
        },
      };
    },

    [types.REMOVE_WISHLIST_ITEM_FAILED]: (state, { payload }) => ({
      ...state,
      selectedItems: {
        ...state.selectedItems,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    /////////////////////////////////// CHECK_ORDER_QUANTITY /////////////////////////////////////////
    [types.CHECK_ORDER_QUANTITY]: (state, { payload }) => ({
      ...state,
      checkOrderQuantity: {
        ...state.checkOrderQuantity,
        loading: true,
        pending: true,
      },
    }),

    [types.CHECK_ORDER_QUANTITY_SUCCESS]: (state, { payload }) => {
      let response = payload.data;
      let updatedMyCartItems = [];

      state.myCart.items.map((item, i) => {
        item["product_unvailable"] = !response[i]["product_available"];
        item["message"] = response[i]["message"];

        item.available = response[i].available;
        item.discount_percentage = response[i].discount_percentage;
        item.price = response[i].price;
        item.qty = response[i].qty;
        item.total_qty = response[i].total_qty;
        item.variation_types = response[i].variation_types;
        item.variation_values = response[i].variation_values;
        item.variations = response[i].variations;
        item.is_item_deleted = response[i].is_item_deleted;
        item.old_price = response[i].old_price;

        updatedMyCartItems.push(item);

        // need to verify ************
        return null;
      });

      var updatedMyCartItemsString = JSON.stringify(updatedMyCartItems);
      // setter
      localStorage.setItem("myCart", updatedMyCartItemsString);

      return {
        ...state,
        checkOrderQuantity: {
          ...state.checkOrderQuantity,
          loading: false,
          pending: false,
          data: payload,
        },
        myCart: {
          items: updatedMyCartItems,
        },
      };
    },

    [types.CHECK_ORDER_QUANTITY_FAILED]: (state, { payload }) => {
      return {
        ...state,
        checkOrderQuantity: {
          ...state.checkOrderQuantity,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },

    [types.DOMESTIC_SHIPPINH_PRODUCT_UPDATE]: (state, { payload }) => {
      return {
        ...state,
        product: {
          ...state.product,
          data: {
            ...state.product.data,
            data: {
              ...state.product.data.data,
              selectedShippingMethod: payload,
            },
          },
        },
      };
    },

    //---------------------Get All Districts-------------------------------//
    [types.GET_ALL_DISTRICTS]: (state, { payload }) => ({
      ...state,
      allDistricts: {
        ...state.allDistricts,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),

    [types.GET_ALL_DISTRICTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allDistricts: {
        ...state.allDistricts,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_ALL_DISTRICTS_FAILED]: (state, { payload }) => ({
      ...state,
      allDistricts: {
        ...state.allDistricts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    //---------------------Get Area by District-------------------------------//
    [types.GET_AREA_BY_DISTRICT]: (state, { payload }) => ({
      ...state,
      areaByDistrict: {
        ...state.areaByDistrict,
        loading: true,
        pending: true,
        hasError: false,
      },
    }),

    [types.GET_AREA_BY_DISTRICT_SUCCESS]: (state, { payload }) => ({
      ...state,
      areaByDistrict: {
        ...state.areaByDistrict,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_AREA_BY_DISTRICT_FAILED]: (state, { payload }) => ({
      ...state,
      areaByDistrict: {
        ...state.areaByDistrict,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
